import React, { useState, useEffect } from "react"
import { useHistory, useLocation } from "react-router-dom"
import { Row, Col, ButtonGroup } from "react-bootstrap"
import axios from "axios"
import {
  CRUDLayout,
  Alert,
  DataStatus,
  ActionButton,
  CreateButton,
  InputSearch,
  Pagination,
  Table,
  THead,
  TBody,
  Tr,
  ThFixed,
  TdFixed,
  Th,
  Td,
  ReadButton,
  FilterButton,
} from "components"
import { TableNumber, DateConvert, RupiahConvert } from "utilities"
import { TransferUpahProduksiApi } from "api"
import { ModalDetail, ModalFilterTransfer } from "./Section"

const ListTransferUpahProduksi = ({ setNavbarTitle }) => {
  const history = useHistory()
  const location = useLocation()

  const [isPageLoading, setIsPageLoading] = useState(true)
  const [dataTransferUpah, setDataTransferUpah] = useState([])

  const [searchConfig, setSearchConfig] = useState({
    status: false,
    key: "",
  })
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: "primary",
    text: "",
  })
  const [modalConfig, setModalConfig] = useState({ show: false, type: 'detail', data: {} })
  const [dataFilter, setDataFilter] = useState({
    filter: {
      active: location?.state?.transfer?.filter?.active,
      tgl_transfer_bahan_produksi_mulai: location?.state?.transfer?.filter?.tgl_transfer_bahan_produksi_mulai,
      tgl_transfer_bahan_produksi_selesai: location?.state?.transfer?.filter?.tgl_transfer_bahan_produksi_selesai,
      tgl_permintaan_produksi_mulai: location?.state?.transfer?.filter?.tgl_permintaan_produksi_mulai,
      tgl_permintaan_produksi_selesai: location?.state?.transfer?.filter?.tgl_permintaan_produksi_selesai,
      item_upah: location?.state?.transfer?.filter?.item_upah,
      vendor: location?.state?.transfer?.filter?.vendor,
    },
    pagination: {
      page: location?.state?.transfer?.filter?.page ?? "1",
      dataLength: location?.state?.transfer?.filter?.dataLength ?? "10",
      totalPage: location?.state?.transfer?.filter?.totalPage ?? "1",
      dataCount: location?.state?.transfer?.filter?.dataCount ?? "0",
    }
  })
  const [dropdown, setDropdown] = useState({
    upah: [{ value: undefined, label: 'Semua' },],
    vendor: [{ value: undefined, label: 'Semua' },]
  })

  const getInitialData = () => {
    setIsPageLoading(true)

    TransferUpahProduksiApi.get({
      q: searchConfig.key,
      page: dataFilter?.pagination?.page,
      per_page: dataFilter?.pagination?.dataLength,
      tgl_transfer_bahan_produksi_mulai: dataFilter?.filter?.tgl_transfer_bahan_produksi_mulai,
      tgl_transfer_bahan_produksi_selesai: dataFilter?.filter?.tgl_transfer_bahan_produksi_selesai,
      tgl_permintaan_produksi_mulai: dataFilter?.filter?.tgl_permintaan_produksi_mulai,
      tgl_permintaan_produksi_selesai: dataFilter?.filter?.tgl_permintaan_produksi_selesai,
      item_upah: dataFilter?.filter?.item_upah,
      vendor: dataFilter?.filter?.vendor,
    })
      .then(data => {
        setDataTransferUpah(data?.data?.data ?? [])
        setDataFilter({
          ...dataFilter,
          pagination: {
            ...dataFilter.pagination,
            dataCount: data?.data?.data_count,
            totalPage: data?.data?.total_page,
          }
        })
      })
      .catch((err) => {
        setDataTransferUpah([])
        setAlertConfig({
          show: true,
          variant: "danger",
          text: "Data gagal dimuat!",
        })
      })
      .finally(() => setIsPageLoading(false))
  }
  const fetchDropdown = () => {
    axios.all([
      TransferUpahProduksiApi.getDropdown({ tipe: "upah" }),
      TransferUpahProduksiApi.getDropdown({ tipe: "vendor" })
    ])
      .then(axios.spread((resUpah, resVendor) => {
        const mapUpah = resUpah.data.data.map(item => ({ value: item.id_item_buaso, label: item.nama_item }))
        const mapVendor = resVendor.data.data.map(item => ({ value: item.id_vendor, label: item.nama_vendor }))

        setDropdown((prev) => ({
          ...prev,
          upah: prev.upah.concat(mapUpah),
          vendor: prev.vendor.concat(mapVendor)
        }))
      }))
  }
  const checkAlert = () => {
    const locationState = location.state

    if (locationState) {
      if (locationState.alert) {
        setAlertConfig({
          show: locationState.alert.show,
          text: locationState.alert.text,
          variant: locationState.alert.variant,
        })
      }
    }
  }
  const onInputSearchChange = (e) => {
    const key = e.target.value

    setSearchConfig({
      ...searchConfig,
      key: e.target.value,
    })
    setDataFilter({
      ...dataFilter,
      pagination: {
        ...dataFilter.pagination,
        page: "1",
        dataLength: "10",
        totalPage: "1",
        dataCount: "0",
      }
    });
    setAlertConfig({
      show: key ? true : false,
      variant: "primary",
      text: "Hasil dari pencarian: " + key,
    })
  }

  useEffect(() => {
    fetchDropdown()

    return () => { setDropdown({ karyawan: [], alat_mesin: [] }) }
  }, [])
  useEffect(() => {
    setNavbarTitle("Transfer Upah Produksi")
    getInitialData()
    checkAlert()

    return () => {
      setIsPageLoading(false)
    }
  }, [
    setNavbarTitle, 
    searchConfig.key, 
    dataFilter?.pagination?.page, 
    dataFilter?.pagination?.dataLength, 
    dataFilter?.filter?.active,
    dataFilter?.filter?.tgl_transfer_bahan_produksi_mulai,
    dataFilter?.filter?.tgl_transfer_bahan_produksi_selesai,
    dataFilter?.filter?.tgl_permintaan_produksi_mulai,
    dataFilter?.filter?.tgl_permintaan_produksi_selesai,
    dataFilter?.filter?.item_upah,
    dataFilter?.filter?.vendor,
  ])

  const PageContent = () => {
    const DataTable = () => (
      <Table>
        <THead>
          <Tr>
            <ThFixed>No.</ThFixed>
            <ThFixed>Aksi</ThFixed>
            <ThFixed>Informasi Transfer Upah Produksi</ThFixed>
            <ThFixed>Informasi Permintaan Produksi</ThFixed>
            <Th>Item Upah</Th>
            <Th width={100}>Qty. Transfer</Th>
            <Th width={100}>Vendor</Th>
            <Th width={150}>Harga Satuan</Th>
            <Th width={150}>Total Harga</Th>
            <Th>Keterangan Transfer</Th>
          </Tr>
        </THead>
        <TBody>
          {dataTransferUpah?.map((val, index) => {
            return (
              <Tr key={index}>
                <Td className="text-center">{TableNumber(dataFilter?.pagination?.page, dataFilter?.pagination?.dataLength, index)}</Td>
                <TdFixed>
                  <ButtonGroup>
                    <ReadButton
                      size="sm"
                      tooltip
                      tooltipText="Detail Transfer Upah Produksi"
                      className="btn btn-block"
                      onClick={() => setModalConfig({ show: true, type: "detail", data: val })}
                    />
                  </ButtonGroup>
                </TdFixed>
                <Td>
                  <div> {val.tgl_transfer_produksi_upah ? DateConvert(new Date(val.tgl_transfer_produksi_upah)).defaultDMY : "-"} </div>
                  <div> {val.no_transfer_produksi_upah ?? "-"} </div>
                </Td>
                <Td>
                  <div> {val.tgl_permintaan_produksi ? DateConvert(new Date(val.tgl_permintaan_produksi)).defaultDMY : "-"} </div>
                  <div> {val.no_permintaan_produksi ?? "-"} </div>
                </Td>
                <Td>{val.nama_item_buaso ?? "-"}</Td>
                <Td className="text-right">{val.qty_transfer ? `${parseFloat(val.qty_transfer ?? 0).toPrecision()} ${val.nama_satuan ?? ""}` : ''}
                </Td>
                <Td> {val.nama_vendor ?? "-"} </Td>
                <Td textRight>{val?.harga_satuan_upah ? RupiahConvert(String(parseInt(val.harga_satuan_upah || 0))).detail : '-'} </Td>
                <Td textRight>{val?.harga_satuan_upah ? RupiahConvert(String(parseInt(val.harga_satuan_upah || 0) * parseFloat(val.qty_transfer || 0))).detail : '-'} </Td>
                <Td> {val.keterangan_transfer ?? "-"} </Td>
              </Tr>
            )
          })}
        </TBody>
      </Table >
    )

    if (!dataTransferUpah || dataTransferUpah?.length < 1) {
      return <DataStatus text="Tidak ada data" />
    }

    return (
      <>
        <small className="font-weight-bold">List Data Transfer Upah Produksi</small>
        <DataTable />

        {!searchConfig.status && (
          <Pagination
            dataLength={dataFilter?.pagination?.dataLength}
            dataNumber={
              dataFilter?.pagination?.page * dataFilter?.pagination?.dataLength - dataFilter?.pagination?.dataLength + 1
            }
            dataPage={
              dataFilter?.pagination?.dataCount < dataFilter?.pagination?.dataLength
                ? dataFilter?.pagination?.dataCount
                : dataFilter?.pagination?.page * dataFilter?.pagination?.dataLength
            }
            dataCount={dataFilter?.pagination?.dataCount}
            currentPage={dataFilter?.pagination?.page}
            totalPage={dataFilter?.pagination?.totalPage}
            onPaginationChange={({ selected }) =>
              setDataFilter({
                ...dataFilter,
                pagination: {
                  ...dataFilter.pagination,
                  page: selected + 1,
                }
              })
            }
            onDataLengthChange={(e) =>
              setDataFilter({
                ...dataFilter,
                pagination: {
                  ...dataFilter.pagination,
                  page: 1,
                  dataLength: e.target.value,
                }
              })
            }
          />
        )}
      </>
    )
  }

  return (
    <CRUDLayout>
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <Row className="mb-2">
            <Col md={8}>
              <InputSearch
                value={searchConfig.key}
                onChange={onInputSearchChange}
              />
            </Col>
              <FilterButton 
                active={dataFilter?.filter?.active}
                onClick={() => setModalConfig({ show: true, type: 'filter' })}
              />
          </Row>
        </CRUDLayout.HeadSearchSection>
        <CRUDLayout.HeadButtonSection>
          <CreateButton
            onClick={() =>
              history.push("/transaksi/transfer-upah-produksi/list-permintaan-produksi", {...location?.state, transfer: dataFilter})
            }
          />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      {/* alert */}
      <Alert
        show={alertConfig.show}
        variant={alertConfig.variant}
        text={alertConfig.text}
        showCloseButton={true}
        onClose={() =>
          setAlertConfig({
            ...alertConfig,
            show: false,
          })
        }
      />

      {/* content */}
      {isPageLoading
        ? (<DataStatus loading={true} text="Memuat data . . ." />)
        : (<PageContent />)
      }

      {/* Modal Detail Transfer Upah Produksi */}
      {modalConfig.show && modalConfig.type === "detail" && (<ModalDetail modalConfig={modalConfig} setModalConfig={setModalConfig} />)}

      {/* Modal Filter  */}
      {modalConfig.show && modalConfig.type === "filter" && (
        <ModalFilterTransfer
          dropdown={dropdown}
          modalConfig={modalConfig}
          setModalConfig={setModalConfig}
          data={dataFilter}
          setData={setDataFilter}
        />
      )}
    </CRUDLayout>
  )
}

export default ListTransferUpahProduksi