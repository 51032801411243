import { useState } from 'react'
import { Modal, Button } from 'react-bootstrap'
import { DatePicker, SelectSearch } from 'components'
import { DateConvert } from 'utilities'
import { Formik } from "formik"

const ModalFilterPermintaanProduksi = ({ dropdown, modalConfig, setModalConfig, data, setData }) => {
  const formInitialValues = {
    tgl_permintaan_produksi_mulai: data?.filter?.tgl_permintaan_produksi_mulai,
    tgl_permintaan_produksi_selesai: data?.filter?.tgl_permintaan_produksi_selesai,
    tgl_job_order_mulai: data?.filter?.tgl_job_order_mulai,
    tgl_job_order_selesai: data?.filter?.tgl_job_order_selesai,
    item_produksi: data?.filter?.item_produksi,
    petugas_produksi: data?.filter?.petugas_produksi,
    depo_produksi: data?.filter?.depo_produksi,
    keperluan_produksi: data?.filter?.keperluan_produksi,
  }
  const formSubmitHandler = (values) => {
    const checkActive = !!Object.values(values).filter(val => val !== undefined).length > 0

    if (checkActive) {
      setData({ 
          filter: {
              ...values,
              active: true
          },
          pagination: {
              ...data.pagination,
              page: 1
          }
      })
    } else { 
        setData({ 
            ...data,
            filter: {
                ...values,
                active: false
            }
        })
    }

    setModalConfig(prev => ({...prev, show: false}))
  }
  const onChangeTglPermintaan = (dates, values, setValues) => {
    const [start, end] = dates
    const startDate = start ? DateConvert(start).default : null
    const endDate = end ? DateConvert(end).default : null

    setValues({
        ...values,
        tgl_permintaan_produksi_mulai: startDate,
        tgl_permintaan_produksi_selesai: endDate,
    })
  }
  const onChangeTglJO = (dates, values, setValues) => {
    const [start, end] = dates
    const startDate = start ? DateConvert(start).default : null
    const endDate = end ? DateConvert(end).default : null

    setValues({
        ...values,
        tgl_job_order_mulai: startDate,
        tgl_job_order_selesai: endDate,
    })
  }
  const onResetButtonClick = (values, setValues) => {
    setValues({
      tgl_permintaan_produksi_mulai: undefined,
      tgl_permintaan_produksi_selesai: undefined,
      tgl_job_order_mulai: undefined,
      tgl_job_order_selesai: undefined,
      item_produksi: undefined,
      petugas_produksi: undefined,
      depo_produksi: undefined,
      keperluan_produksi: undefined
    })
  }

  return (
    <Formik
      enableReinitialize
      initialValues={formInitialValues}
      onSubmit={formSubmitHandler}>
      
      {({values, handleSubmit, setFieldValue, setValues}) => (
        <Modal
          show={modalConfig.show && modalConfig.type === 'filter'}
          onHide={() => setModalConfig(prev => ({ ...prev, show: false }))}
        >
          <Modal.Header closeButton>
            <b>Filter Data</b>
          </Modal.Header>
          <Modal.Body>
            <DatePicker
              selectsRange
              label="Tgl. Permintaan Produksi"
              placeholderText="Pilih tanggal permintaan produksi"
              startDate={values.tgl_permintaan_produksi_mulai ? new Date(values.tgl_permintaan_produksi_mulai) : ''}
              endDate={values.tgl_permintaan_produksi_selesai ? new Date(values.tgl_permintaan_produksi_selesai) : ''}
              onChange={(dates) => onChangeTglPermintaan(dates, values, setValues)}
              monthsShown={2}
            />
            <DatePicker
              selectsRange
              label="Tgl. Job Order"
              placeholderText="Pilih tanggal job order"
              startDate={values.tgl_job_order_mulai ? new Date(values.tgl_job_order_mulai) : ''}
              endDate={values.tgl_job_order_selesai ? new Date(values.tgl_job_order_selesai) : ''}
              onChange={(dates) => onChangeTglJO(dates, values, setValues)}
              monthsShown={2}
            />
            <SelectSearch
              key={values.item_produksi}
              label="Item Produksi"
              placeholder="Pilih item produksi"
              defaultValue={dropdown?.item_produksi?.find(item => item.value === values.item_produksi)}
              option={dropdown.item_produksi}
              onChange={val => setFieldValue('item_produksi', val.value)}
            />
            <SelectSearch
              key={values.petugas_produksi}
              label="Petugas Produksi"
              placeholder="Pilih petugas produksi"
              defaultValue={dropdown?.petugas?.find(item => item.value === values.petugas_produksi)}
              option={dropdown.petugas}
              onChange={val => setFieldValue('petugas_produksi', val.value)}
            />
            <SelectSearch
              key={values.depo_produksi}
              label="Depo Produksi"
              placeholder="Pilih depo produksi"
              defaultValue={dropdown?.depo?.find(item => item.value === values.depo_produksi)}
              option={dropdown.depo}
              onChange={val => setFieldValue('depo_produksi', val.value)}
            />
            <SelectSearch
              key={values.keperluan_produksi}
              label="Keperluan Produksi"
              placeholder="Pilih keperluan produksi"
              defaultValue={dropdown?.keperluan?.find(item => item.value === values.keperluan_produksi)}
              option={dropdown.keperluan}
              onChange={val => setFieldValue('keperluan_produksi', val.value)}
            />
          </Modal.Body>
          <Modal.Footer>
          <Button
            variant="outline-secondary"
            onClick={() => onResetButtonClick(values, setValues)}
          >
            RESET
          </Button>
          <Button
            variant="primary"
            type="submit"
            onClick={handleSubmit}
          >
            FILTER
          </Button>
          </Modal.Footer>
        </Modal >
      )}
    </Formik>
  )
}

export default ModalFilterPermintaanProduksi