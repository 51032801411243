import Services from "../../../services";

class SeleksiVendorApi {
  get(params) {
    return Services.get("/seleksi_vendor/page", { params });
  }

  getSingle(params) {
    return Services.get("/seleksi_vendor/single", { params });
  }

  getPurchaseRequest(params) {
    return Services.get("/seleksi_vendor/purchase_request", { params });
  }

  getNomor(params) {
    return Services.get("/seleksi_vendor/no_baru", { params });
  }

  createOrUpdate(data) {
    return Services.post("/seleksi_vendor", data);
  }

  delete(data) {
    return Services.post("/seleksi_vendor/delete", data);
  }
}

export default new SeleksiVendorApi();
