// React
import React, { useState, useEffect } from "react"

// Components
import { Card, Col, Nav, Row, Tab } from "react-bootstrap"
import { CRUDLayout, InputSearch, DataStatus, Alert, Select } from "../../../components"

// View Components
import { TabItem } from "./components"

const HargaPerkiraanSendiri = ({ setNavbarTitle }) => {
  const title = "Harga Satuan Rata-Rata"
  const buaso = [
    {
      label: "Semua Data",
      value: ""
    },
    {
      label: "Bahan",
      value: "1"
    },
    // {
    //   label: "Upah",
    //   value: "2"
    // },
    // {
    //   label: "Alat dan mesin",
    //   value: "3"
    // },
    // {
    //   label: "Subkon",
    //   value: "4"
    // },
    // {
    //   label: "Overhead",
    //   value: "5"
    // },
  ]

  // STATE LOADING
  const [isLoading, setIsLoading] = useState(false)

  // STATE DATA SATUAN
  const [key, setKey] = useState("")
  const [filter, setFilter] = useState("")
  // ALERT STATE
  const [showAlert, setShowAlert] = useState(false)
  // KONFIGURASI ALERT
  const [alertConfig, setAlertConfig] = useState({
    variant: "primary",
    text: "",
  })

  // ON COMPONENT MOUNT
  useEffect(() => {
    setNavbarTitle(title) // SET JUDUL NAVBAR

    return () => {
      setIsLoading(false)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setNavbarTitle])

  return (
    <CRUDLayout>
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <Row>
            <Col md="8">
              <InputSearch
                onChange={(e) => {
                  setTimeout(() => {
                    const value = e.target.value

                    setKey(value)

                    setAlertConfig({
                      text: value,
                      variant: 'primary'
                    })
                    setShowAlert(Boolean(value && value !== ""))
                  }, 1000)
                }}
                onSubmit={(e) => e.preventDefault()}
              />
            </Col>
          </Row>
        </CRUDLayout.HeadSearchSection>

        {/* <CRUDLayout.HeadButtonSection>
          <Select onChange={e => setFilter(e.target.value)}>
            <option value="" hidden>Pilih Buaso</option>
            {buaso.map((val, index) => <option key={index} value={val.value}>{val.label}</option>)}
          </Select>
        </CRUDLayout.HeadButtonSection> */}
      </CRUDLayout.Head>

      {/* Alert */}
      <Alert
        show={showAlert}
        showCloseButton={true}
        variant={alertConfig.variant}
        text={alertConfig.text}
        onClose={() => setShowAlert(false)}
      />

      {isLoading ? (
        <DataStatus loading={isLoading} text="Memuat Data" />
      ) : (
        <>
          <div className="mt-4"><b>List Data Harga Satuan Rata-Rata</b></div>
          {/* <Card> */}
            {/* <Tab.Container id="left-tabs-example" defaultActiveKey="2">
              <Card.Header className="d-flex justify-content-between align-items-center">
                <Nav variant="tabs"> */}
                  {/* <Nav.Item>
                    <Nav.Link eventKey="1">HPS Per Satuan Beli</Nav.Link>
                  </Nav.Item> */}
                  {/* <Nav.Item>
                    <Nav.Link eventKey="2">HPS Per Satuan Pakai</Nav.Link>
                  </Nav.Item>
                </Nav>
              </Card.Header>
              <Tab.Content> */}
                {/* <Tab.Pane eventKey="1">
                  <Card.Body>
                    <TabItem q={key} filter={filter} value='beli' />
                  </Card.Body>
                </Tab.Pane> */}
                {/* <Tab.Pane eventKey="2">
                  <Card.Body> */}
                    <TabItem q={key} filter={filter} value='pakai' />
                  {/* </Card.Body>
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </Card> */}
        </>
      )}

      {/* MODAL */}
      {/* {isUpdateForm.show && <UbahModal />} */}
    </CRUDLayout>
  )
}

export default HargaPerkiraanSendiri
