import React, { useState, useEffect } from "react";
import { Row, Col, Card } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import Axios from "axios";
import {
  Alert,
  BackButton,
  ActionButton,
  DataStatus,
  Input,
  Table,
  THead,
  TBody,
  Tr,
  ThFixed,
  TdFixed,
  Th,
  Td,
  DatePicker,
  TextArea,
  SelectSearch,
} from "components";
import { DateConvert, RupiahConvert } from "utilities";
import { PurchaseOrderApi } from "api";
import { IoAddOutline, IoTrashOutline } from "react-icons/io5";
import { date } from "yup/lib/locale";

const TambahPurchaseOrder = ({ setNavbarTitle }) => {
  const history = useHistory();
  const { id } = useParams();
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [isFetchingFailed, setIsFetchingFailed] = useState(false);
  const [isFetchingPO, setIsFetchingPO] = useState(false);
  const [dataPurchaseOrder, setDataPurchaseOrder] = useState({});
  const [dataListItemPurchaseOrder, setDataListItemPurchaseOrder] = useState([]);
  const [dropdownVendor, setDropdownVendor] = useState({});
  const [noBaru, setNoBaru] = useState("");
  const [loadiNoBaru, setLoadiNoBaru] = useState(true);
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: "primary",
    text: "",
  });

  console.log("id", id);

  const getInitialData = () => {
    setIsPageLoading(true);
    Axios.all([PurchaseOrderApi.getVendor()])
      .then(
        Axios.spread((dataVendor) => {
          setDropdownVendor(dataVendor.data.data);
        })
      )
      .catch((err) => console.log(err))
      .finally(() => setIsPageLoading(false));
  };

  const getPurchaseOrderSingleVendor = (id_vendor) => {
    setIsFetchingPO(true);
    setDataListItemPurchaseOrder([]);
    PurchaseOrderApi.getSingleVendor({ id_vendor })
      .then(({ data }) => {
        setDataPurchaseOrder({ ...data.data });
      })
      .catch(() => {
        setIsFetchingFailed(true);
        setAlertConfig({
          show: true,
          variant: "danger",
          text: "Data gagal dimuat!",
        });
      })
      .finally(() => setIsFetchingPO(false));
  };

  const getNoPurchaseOrder = (date, setFieldValue) => {
    const tgl = date ? date : new Date().toISOString().slice(0, 10);
    Axios.all([PurchaseOrderApi.getNomor({ tanggal: tgl })])
      .then(
        Axios.spread((data) => {
          setNoBaru(data.data.data);
        })
      )
      .catch(() => {
        // setFieldValue("tgl_purchase_order", "");
        // setFieldValue("no_purchase_order", "");
        setAlertConfig({
          show: true,
          variant: "danger",
          text: "Nomor gagal diperoleh!",
        });
      });
    // .finally(() => {
    //   loadiNoBaru(false);
    // });
  };
  useEffect(() => {
    setNavbarTitle("Purchase Order");
    getInitialData();
    getNoPurchaseOrder();
    getPurchaseOrderSingleVendor(id);
    return () => {
      setIsPageLoading(false);
    };
  }, []);

  useEffect(() => {
    console.log(dataListItemPurchaseOrder);
    return () => {};
  }, [dataListItemPurchaseOrder]);

  const formInitialValues = {
    id_vendor: id,
    tgl_purchase_order: new Date(),
    no_purchase_order: noBaru,
    tgl_pengiriman: new Date(),
    tgl_jatuh_tempo: new Date(),
    alamat_tujuan_pengiriman: dataPurchaseOrder.alamat_tujuan_pengiriman
      ? dataPurchaseOrder.alamat_tujuan_pengiriman
      : "",
    keterangan: dataPurchaseOrder.keterangan ? dataPurchaseOrder.keterangan : "",
    diskon: dataPurchaseOrder.diskon ? dataPurchaseOrder.diskon : "",
    ppn: dataPurchaseOrder.ppn ? dataPurchaseOrder.ppn : "",
    dimensi: dataPurchaseOrder.dimensi ? dataPurchaseOrder.dimensi : "0",
    pj: dataPurchaseOrder.pj ? dataPurchaseOrder.pj : "",
    lb: dataPurchaseOrder.lb ? dataPurchaseOrder.lb : "",
    tb: dataPurchaseOrder.tb ? dataPurchaseOrder.tb : "",
    pembayaran: "",
  };

  const formValidationSchema = Yup.object().shape({
    id_vendor: Yup.string().required("Pilih vendor"),
    tgl_purchase_order: Yup.string().required("Pilih tanggal purchase order"),
    tgl_pengiriman: Yup.string().required("Pilih tanggal pengiriman"),
    alamat_tujuan_pengiriman: Yup.string().required("Masukan alamat tujuan pengiriman"),
    diskon: Yup.string().required(),
    ppn: Yup.string().required(),
    pembayaran: Yup.string().required("Masukan Pembayaran"),
  });

  const formSubmitHandler = (values) => {
    const detailData = dataListItemPurchaseOrder.map((val) => {
      return {
        id_item_buaso: val.id_item_buaso,
        qty_order: val.qty_order,
        harga_kesepakatan: val.harga_kesepakatan,
        id_seleksi_vendor_detail: [val.id_seleksi_vendor_detail],
      };
    });

    const finalValues = {
      ...values,
      no_purchase_order: noBaru,
      detail: detailData,
    };

    PurchaseOrderApi.createOrUpdate(finalValues)
      .then(() => {
        history.push("/transaksi/purchase-order", {
          alert: {
            show: true,
            variant: "primary",
            text: "Data berhasil ditambah!",
          },
        });
      })
      .catch(() => {
        setAlertConfig({
          show: true,
          variant: "danger",
          text: "Data gagal ditambah!",
        });
      });
  };

  // COMPONENTS
  const InfoSection = () => {
    const InfoItem = ({ title, value }) => (
      <tr>
        <td>
          <small>{title}</small>
        </td>
        <td>
          <small className="pl-4 pr-2">:</small>
        </td>
        <td>
          <small>{value}</small>
        </td>
      </tr>
    );

    return (
      <>
        <table>
          <tbody>
            <InfoItem
              title="Kode Vendor"
              value={dataPurchaseOrder.kode_vendor ? dataPurchaseOrder.kode_vendor : "-"}
            />
            <InfoItem
              title="Vendor"
              value={dataPurchaseOrder.nama_vendor ? dataPurchaseOrder.nama_vendor : "-"}
            />
            <InfoItem
              title="Contact Person"
              value={dataPurchaseOrder.contact_person ? dataPurchaseOrder.contact_person : "-"}
            />
            <InfoItem
              title="Alamat Vendor"
              value={dataPurchaseOrder.alamat_vendor ? dataPurchaseOrder.alamat_vendor : "-"}
            />
          </tbody>
        </table>
        <hr />
      </>
    );
  };

  const ListItemSeleksiVendor = ({ dataListItemPurchaseOrder, setDataListItemPurchaseOrder }) => {
    return (
      <>
        <div className="py-2 pl-1">
          <b>List Item Seleksi Vendor</b>
        </div>
        {dataPurchaseOrder.detail ? (
          dataPurchaseOrder.detail.length > 0 ? (
            <Table>
              <THead>
                <Tr>
                  <ThFixed>No.</ThFixed>
                  <ThFixed>Kode Item</ThFixed>
                  <Th>Nama Item</Th>
                  <Th>Qty</Th>
                  <Th>Satuan Beli</Th>
                  <Th>No. Purchase Request</Th>
                  <Th>Tgl. Seleksi</Th>
                  <ThFixed>Aksi</ThFixed>
                </Tr>
              </THead>
              <TBody>
                {dataPurchaseOrder.detail.map((val, index) => (
                  <Tr key={index}>
                    <TdFixed>{index + 1}</TdFixed>
                    <TdFixed>{val.kode_item ? val.kode_item : "-"}</TdFixed>
                    <Td>
                      <div style={{ width: "200px" }}>
                        {/* {val.dimensi === "3"
                          ? `${val.nama_item} (${val.pj} X ${val.lb} X ${val.tb})`
                          : val.dimensi === "2"
                          ? `${val.nama_item} (${val.pj} X ${val.lb})`
                          : val.dimensi === "1"
                          ? `${val.nama_item} (${val.pj})`
                          : val.dimensi === "0"
                          ? val.nama_item
                          : "-"} */}
                        {val.nama_item}
                      </div>
                    </Td>
                    <Td textRight>
                      {val.qty_order ? parseFloat(val.qty_order).toPrecision() : "-"}
                    </Td>
                    <Td>{val.satuan_beli ? val.satuan_beli : "-"}</Td>
                    <Td>{val.no_purchase_request ? val.no_purchase_request : "-"}</Td>
                    <Td>
                      {val.tgl_seleksi_vendor
                        ? DateConvert(new Date(val.tgl_seleksi_vendor)).detail
                        : "-"}
                    </Td>
                    <Td>
                      <ActionButton
                        size="sm"
                        text={<IoAddOutline />}
                        onClick={() =>
                          setDataListItemPurchaseOrder([...dataListItemPurchaseOrder, val])
                        }
                        disable={dataListItemPurchaseOrder.find(
                          (po) => po.id_seleksi_vendor_detail === val.id_seleksi_vendor_detail
                        )}
                      />
                    </Td>
                  </Tr>
                ))}
              </TBody>
            </Table>
          ) : (
            <DataStatus text="Tidak ada data" />
          )
        ) : (
          <DataStatus text="Tidak ada data" />
        )}
      </>
    );
  };

  const ListItemPurchaseOrderSection = ({
    values,
    errors,
    touched,
    handleChange,
    dataListItemPurchaseOrder,
    setDataListItemPurchaseOrder,
  }) => {
    let total = 0;
    let nilaiDiskon = 0;
    let setelahDiskon = 0;
    let nilaiPPN = 0;
    let setelahPPN = 0;

    const TableFooterItems = ({ text, input, value }) => (
      <Tr>
        <Td colSpan={6} textRight>
          <b>{text}</b>
        </Td>
        <Td textRight colSpan={2}>
          <div className="d-flex align-items-center justify-content-between text-nowrap">
            {input ? (
              <div className="d-flex justify-content-center align-items-center">
                <div style={{ width: "70px" }}>{input}</div>
                <div className="pb-1 ml-2">
                  <b>%</b>
                </div>
              </div>
            ) : (
              <div></div>
            )}
            <div className="text-right">
              <b>{value}</b>
            </div>
          </div>
        </Td>
      </Tr>
    );

    return (
      <>
        <div className="py-2 pl-1">
          <b>List Item Purchase Order</b>
        </div>
        {dataListItemPurchaseOrder ? (
          dataListItemPurchaseOrder.length > 0 ? (
            <>
              <Table>
                <THead>
                  <Tr>
                    <ThFixed>No.</ThFixed>
                    <ThFixed>Kode Item</ThFixed>
                    <Th>Nama Item</Th>
                    <Th>Satuan Beli</Th>
                    <Th>Qty Order</Th>
                    <Th>Harga Kesepakatan</Th>
                    <Th>Jumlah</Th>
                    <ThFixed>Aksi</ThFixed>
                  </Tr>
                </THead>
                <TBody>
                  {dataListItemPurchaseOrder.map((val, index) => {
                    total = total + val.harga_kesepakatan * val.qty_order;
                    nilaiDiskon = values.diskon
                      ? parseInt(values.diskon) === 0
                        ? 0
                        : parseInt((values.diskon / 100) * total)
                      : 0;
                    setelahDiskon = parseInt(total - nilaiDiskon);
                    nilaiPPN = values.ppn
                      ? parseInt(values.ppn) === 0
                        ? 0
                        : parseInt((values.ppn / 100) * setelahDiskon)
                      : 0;
                    setelahPPN = parseInt(setelahDiskon + nilaiPPN);

                    return (
                      <Tr key={index}>
                        <TdFixed>{index + 1}</TdFixed>
                        <TdFixed>{val.kode_item}</TdFixed>
                        <Td>
                          <div style={{ width: "200px" }}>
                            {/* {val.dimensi === "3"
                              ? `${val.nama_item} (${val.pj} X ${val.lb} X ${val.tb})`
                              : val.dimensi === "2"
                              ? `${val.nama_item} (${val.pj} X ${val.lb})`
                              : val.dimensi === "1"
                              ? `${val.nama_item} (${val.pj})`
                              : val.dimensi === "0"
                              ? val.nama_item
                              : ""} */}
                            {val.nama_item}
                          </div>
                        </Td>
                        <Td>{val.satuan_beli}</Td>
                        <Td textRight>{val.qty_order}</Td>
                        <Td textRight>
                          {RupiahConvert(parseInt(val.harga_kesepakatan).toString()).detail}
                        </Td>
                        <Td textRight>
                          {
                            RupiahConvert(
                              parseInt(val.qty_order * val.harga_kesepakatan).toString()
                            ).detail
                          }
                        </Td>
                        <Td className="d-flex justify-content-center">
                          <ActionButton
                            size="sm"
                            variant="danger"
                            text={<IoTrashOutline />}
                            onClick={() => {
                              const filterData = dataListItemPurchaseOrder.filter(
                                (data) => data !== val
                              );
                              setDataListItemPurchaseOrder(filterData);
                            }}
                          />
                        </Td>
                      </Tr>
                    );
                  })}
                  <TableFooterItems
                    text="Total"
                    value={RupiahConvert(parseInt(total).toString()).detail}
                  />
                  <Tr>
                    <Td colSpan={6} textRight>
                      <b>Diskon</b>
                    </Td>
                    <Td textRight colSpan={2}>
                      <div className="d-flex align-items-center justify-content-between text-nowrap">
                        <div className="d-flex justify-content-center align-items-center">
                          <div style={{ width: "70px" }}>
                            <input
                              type="number"
                              name="diskon"
                              className={`form-control form-control-sm ${
                                errors.diskon && touched.diskon && "border-danger"
                              }`}
                              value={values.diskon}
                              onChange={handleChange}
                              min={0}
                              max={100}
                              required
                            />
                          </div>
                          <div className="pb-1 ml-2">
                            <b>%</b>
                          </div>
                        </div>
                        <div className="text-right">
                          <b>{RupiahConvert(nilaiDiskon.toString()).detail}</b>
                        </div>
                      </div>
                    </Td>
                  </Tr>
                  <TableFooterItems
                    text="Total Setelah Diskon"
                    value={RupiahConvert(setelahDiskon.toString()).detail}
                  />
                  <Tr>
                    <Td colSpan={6} textRight>
                      <b>PPN</b>
                    </Td>
                    <Td textRight colSpan={2}>
                      <div className="d-flex align-items-center justify-content-between text-nowrap">
                        <div className="d-flex justify-content-center align-items-center">
                          <div style={{ width: "70px" }}>
                            <input
                              type="number"
                              name="ppn"
                              className={`form-control form-control-sm ${
                                errors.ppn && touched.ppn && "border-danger"
                              }`}
                              value={values.ppn}
                              onChange={handleChange}
                              min={0}
                              max={100}
                              required
                            />
                          </div>
                          <div className="pb-1 ml-2">
                            <b>%</b>
                          </div>
                        </div>
                        <div className="text-right">
                          <b>{RupiahConvert(nilaiPPN.toString()).detail}</b>
                        </div>
                      </div>
                    </Td>
                  </Tr>
                  <TableFooterItems
                    text="Total Setelah PPN"
                    value={RupiahConvert(setelahPPN.toString()).detail}
                  />
                </TBody>
              </Table>
            </>
          ) : (
            <DataStatus text="Tidak ada data" />
          )
        ) : (
          <DataStatus text="Tidak ada data" />
        )}
        <hr />
      </>
    );
  };
  return (
    <Card>
      <Card.Header className="d-flex justify-content-between align-items-center">
        <b>Tambah Data Purchase Order</b>
        <BackButton onClick={() => history.goBack()} />
      </Card.Header>
      <Card.Body>
        <Alert
          showCloseButton
          show={alertConfig.show}
          variant={alertConfig.variant}
          text={alertConfig.text}
          onClose={() =>
            setAlertConfig({
              ...alertConfig,
              show: false,
            })
          }
        />
        {isPageLoading ? (
          <DataStatus loading={true} text="Memuat data . . ." />
        ) : isFetchingFailed ? (
          <DataStatus text="Data gagal dimuat, tidak dapat menambah data!" />
        ) : (
          <Formik
            initialValues={formInitialValues}
            validationSchema={formValidationSchema}
            onSubmit={formSubmitHandler}
          >
            {({
              values,
              errors,
              touched,
              isSubmitting,
              setFieldValue,
              handleChange,
              handleSubmit,
            }) => (
              <form onSubmit={handleSubmit}>
                <Row>
                  <Col>
                    <DatePicker
                      label="Tgl. Purchase Order"
                      className="mt-1"
                      selected={
                        values.tgl_purchase_order ? new Date(values.tgl_purchase_order) : ""
                      }
                      onChange={(e) => {
                        const value = e.toISOString().slice(0, 10);
                        setFieldValue("tgl_purchase_order", value);
                        getNoPurchaseOrder(value, setFieldValue);
                      }}
                      error={errors.tgl_purchase_order && touched.tgl_purchase_order && true}
                      errorText={errors.tgl_purchase_order}
                    />
                  </Col>
                  <Col>
                    <Input
                      type="text"
                      label="No. Purchase Order"
                      placeholder="Pilih tanggal untuk menentukan nomor"
                      value={noBaru}
                      error={errors.no_purchase_order && touched.no_purchase_order && true}
                      errorText={errors.no_purchase_order}
                      readOnly
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <DatePicker
                      label="Tgl. Pengiriman"
                      className="mt-1"
                      selected={values.tgl_pengiriman ? new Date(values.tgl_pengiriman) : ""}
                      onChange={(e) => {
                        const value = e.toISOString().slice(0, 10);
                        setFieldValue("tgl_pengiriman", value);
                      }}
                      error={errors.tgl_pengiriman && touched.tgl_pengiriman && true}
                      errorText={errors.tgl_pengiriman}
                    />
                    <DatePicker
                      label="Tgl. Jatuh Tempo Pembayaran"
                      className="mt-1"
                      selected={values.tgl_jatuh_tempo ? new Date(values.tgl_jatuh_tempo) : ""}
                      onChange={(e) => {
                        const value = e.toISOString().slice(0, 10);
                        setFieldValue("tgl_jatuh_tempo", value);
                      }}
                      // error={errors.tgl_jatuh_tempo && touched.tgl_jatuh_tempo && true}
                      // errorText={errors.tgl_jatuh_tempo}
                    />
                    <SelectSearch
                      label="Vendor"
                      name="id_vendor"
                      placeholder="Pilih Vendor"
                      onChange={(val) => {
                        setFieldValue("id_vendor", val.value);
                      }}
                      option={dropdownVendor.map((val) => {
                        return {
                          value: val.id_vendor,
                          label: val.nama_vendor,
                        };
                      })}
                      defaultValue={{
                        value: values.id_vendor ?? "",
                        label:
                          values.id_vendor === ""
                            ? "Pilih Satuan"
                            : dropdownVendor.find((val) => val.id_vendor === values.id_vendor)
                                ?.nama_vendor ?? "Pilih Satuan",
                      }}
                      isDisabled={true}
                      error={errors.id_vendor && touched.id_vendor}
                      errorText={errors.id_vendor && touched.id_vendor && errors.id_vendor}
                    />
                    <TextArea
                      label="Alamat Tujuan Pengiriman"
                      name="alamat_tujuan_pengiriman"
                      placeholder="Masukan alamat tujuan pengiriman"
                      value={values.alamat_tujuan_pengiriman}
                      onChange={handleChange}
                      error={
                        errors.alamat_tujuan_pengiriman && touched.alamat_tujuan_pengiriman && true
                      }
                      errorText={errors.alamat_tujuan_pengiriman}
                    />
                  </Col>
                  <Col>
                    <TextArea
                      label="Keterangan"
                      name="keterangan"
                      placeholder="Masukan keterangan"
                      value={values.keterangan}
                      onChange={handleChange}
                      error={errors.keterangan && touched.keterangan && true}
                      errorText={errors.keterangan}
                      rows={11}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <TextArea
                      label="Pembayaran"
                      name="pembayaran"
                      placeholder="Masukan Jangka Waktu Pembayaran"
                      value={values.pembayaran}
                      onChange={handleChange}
                      error={errors.pembayaran && touched.pembayaran && true}
                      errorText={errors.pembayaran}
                    />
                  </Col>
                </Row>
                <hr />
                {isFetchingPO ? (
                  <DataStatus loading={isFetchingPO} text="Memuat data ..." />
                ) : (
                  <>
                    <InfoSection />
                    <ListItemSeleksiVendor
                      dataListItemPurchaseOrder={dataListItemPurchaseOrder}
                      setDataListItemPurchaseOrder={setDataListItemPurchaseOrder}
                    />
                    <ListItemPurchaseOrderSection
                      values={values}
                      errors={errors}
                      touched={touched}
                      handleChange={handleChange}
                      setFieldValue={setFieldValue}
                      dataListItemPurchaseOrder={dataListItemPurchaseOrder}
                      setDataListItemPurchaseOrder={setDataListItemPurchaseOrder}
                    />
                    {dataListItemPurchaseOrder && dataListItemPurchaseOrder.length > 0 && (
                      <div className="d-flex justify-content-end my-3">
                        <ActionButton
                          type="submit"
                          text="Simpan Data Purchase Order"
                          loading={isSubmitting}
                        />
                      </div>
                    )}
                  </>
                )}
              </form>
            )}
          </Formik>
        )}
      </Card.Body>
    </Card>
  );
};

export default TambahPurchaseOrder;
