import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Row, Col, ButtonGroup } from "react-bootstrap";
import {
  CRUDLayout,
  Alert,
  DataStatus,
  BackButton,
  ActionButton,
  InputSearch,
  Pagination,
  Table,
  THead,
  TBody,
  Tr,
  ThFixed,
  TdFixed,
  Th,
  Td,
} from "../../../../components";
import { TableNumber, DateConvert, RupiahConvert } from "../../../../utilities";
import { PurchaseOrderApi } from "../../../../api";
import { IoAddOutline } from "react-icons/io5";

const VendorTerseleksi = ({ setNavbarTitle }) => {
  const history = useHistory();
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [dataVendorTerseleksi, setDataVendorTerseleksi] = useState([]);
  const [paginationConfig, setPaginationConfig] = useState({
    page: "1",
    dataLength: "10",
    totalPage: "1",
    dataCount: "0",
    key: "",
  });
  const [searchConfig, setSearchConfig] = useState({
    status: false,
    key: "",
  });
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: "primary",
    text: "",
  });

  const getInitialData = () => {
    setIsPageLoading(true);
    setAlertConfig({
      ...alertConfig,
      show: false,
    });

    PurchaseOrderApi.getVendor({
      q: searchConfig.key,
      page: paginationConfig.page,
      per_page: paginationConfig.dataLength,
    })
      .then(({ data }) => {
        setDataVendorTerseleksi(data.data);
        setPaginationConfig({
          ...paginationConfig,
          dataCount: data.data_count,
          totalPage: data.total_page,
        });
      })
      .catch(() =>
        setAlertConfig({
          show: true,
          variant: "danger",
          text: "Data gagal dimuat!",
        })
      )
      .finally(() => setIsPageLoading(false));
  };

  useEffect(() => {
    setNavbarTitle("LIST SELEKSI VENDOR AVAILABLE");
    getInitialData();

    return () => {
      setIsPageLoading(false);
    };
  }, [setNavbarTitle, searchConfig.key, paginationConfig.page, paginationConfig.dataLength]);

  const ItemCollapse = ({ data }) => {
    const [isReadMoreClick, setisReadMoreClick] = useState(false);

    return (
      <>
        <ul className="pl-3 mb-1" style={{ fontSize: "13px" }}>
          {data.map((brg, index) => {
            if (isReadMoreClick) {
              return <li index={index}>{brg.nama_item}</li>;
            } else {
              return index <= 1 && <li index={index}>{brg.nama_item}</li>;
            }
          })}
        </ul>
        {data.length < 3 ? (
          ""
        ) : (
          <div
            className="text-primary"
            style={{
              cursor: "pointer",
              textDecoration: "underline",
              // fontWeight: "bold",
            }}
            onClick={() => {
              setisReadMoreClick((prev) => !prev);
            }}
          >
            {isReadMoreClick ? "Sembunyikan" : "Selengkapnya..."}
          </div>
        )}
      </>
    );
  };

  const PageContent = () => {
    const DataTable = () => (
      <Table>
        <THead>
          <Tr>
            <ThFixed>No</ThFixed>
            <ThFixed>Tgl. Seleksi Vendor</ThFixed>
            <Th>No. Seleksi Vendor</Th>
            <Th>Vendor</Th>
            <Th>Item Purchase Request</Th>
            <ThFixed>Qty. Order</ThFixed>
            <Th>Harga Kesepakatan</Th>
            <Th>Total Harga</Th>
            <Th>Status</Th>
            <ThFixed>Aksi</ThFixed>
          </Tr>
        </THead>
        <TBody>
          {dataVendorTerseleksi.map((val, index) => {
            const total = parseInt(val.harga_kesepakatan) * parseInt(val.qty_order);
            return (
              <Tr key={index}>
                <TdFixed>
                  {TableNumber(paginationConfig.page, paginationConfig.dataLength, index)}
                </TdFixed>
                <Td>{DateConvert(new Date(val.tgl_seleksi_vendor)).defaultDMY}</Td>
                <Td>{val.no_seleksi_vendor}</Td>
                <Td>
                  <div className="py-1" style={{ width: "200px" }}>
                    {val.nama_vendor}
                  </div>
                </Td>
                <Td>
                  {val.list_barang ? (
                    val.list_barang.length > 0 ? (
                      <ItemCollapse data={val.list_barang} />
                    ) : (
                      "-"
                    )
                  ) : (
                    "-"
                  )}
                </Td>
                <TdFixed>
                  <div className="text-right">
                    {val.qty_order ? parseFloat(val.qty_order).toPrecision() : "-"}
                  </div>
                </TdFixed>
                <Td className="text-right">{RupiahConvert(val.harga_kesepakatan).detail}</Td>
                <Td className="text-right">{RupiahConvert(total.toString()).detail}</Td>
                <Td>
                  {val.is_in_po === false ? (
                    <div className="text-danger text-center">Belum Di Buatkan PO</div>
                  ) : (
                    <div className="text-success text-center">Sudah Di Buatkan PO</div>
                  )}
                </Td>
                <Td>
                  <ButtonGroup size="sm">
                    <ActionButton
                      size="sm"
                      className="mx-1 text-nowrap"
                      text={<IoAddOutline />}
                      onClick={() =>
                        history.push("/transaksi/purchase-order/tambah/" + val.id_vendor)
                      }
                    />
                  </ButtonGroup>
                </Td>
              </Tr>
            );
          })}
        </TBody>
      </Table>
    );

    return (
      <>
        <DataTable />
        <Pagination
          dataLength={paginationConfig.dataLength}
          dataNumber={
            paginationConfig.page * paginationConfig.dataLength - paginationConfig.dataLength + 1
          }
          dataPage={
            paginationConfig.dataCount < paginationConfig.dataLength
              ? paginationConfig.dataCount
              : paginationConfig.page * paginationConfig.dataLength
          }
          dataCount={paginationConfig.dataCount}
          currentPage={paginationConfig.page}
          totalPage={paginationConfig.totalPage}
          onPaginationChange={({ selected }) =>
            setPaginationConfig({
              ...paginationConfig,
              page: selected + 1,
            })
          }
          onDataLengthChange={(e) =>
            setPaginationConfig({
              ...paginationConfig,
              page: 1,
              dataLength: e.target.value,
            })
          }
        />
      </>
    );
  };

  return (
    <CRUDLayout>
      {/* head section */}
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <Row>
            <Col md="8">
              <InputSearch
                onChange={(e) => {
                  const key = e.target.value;
                  setSearchConfig({
                    ...searchConfig,
                    key: e.target.value,
                  });
                  setAlertConfig({
                    show: key ? true : false,
                    variant: "primary",
                    text: "Hasil dari pencarian: " + key,
                  });
                }}
              />
            </Col>
          </Row>
        </CRUDLayout.HeadSearchSection>
        <CRUDLayout.HeadButtonSection>
          <BackButton onClick={() => history.push("/transaksi/purchase-order")} />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      {/* alert */}
      <Alert
        show={alertConfig.show}
        variant={alertConfig.variant}
        text={alertConfig.text}
        showCloseButton={true}
        onClose={() =>
          setAlertConfig({
            ...alertConfig,
            show: false,
          })
        }
      />

      {/* content */}
      {isPageLoading ? (
        <DataStatus loading={true} text="Memuat data . . ." />
      ) : dataVendorTerseleksi ? (
        dataVendorTerseleksi.length > 0 ? (
          <PageContent />
        ) : (
          <DataStatus text="Tidak ada data" />
        )
      ) : (
        <DataStatus text="Data gagal dimuat" />
      )}
    </CRUDLayout>
  );
};

export default VendorTerseleksi;
