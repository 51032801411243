import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  CRUDLayout,
  Alert,
  DataStatus,
  CreateButton,
  ReadButton,
  UpdateButton,
  ActionButton,
  InputSearch,
  Pagination,
  Table,
  THead,
  TBody,
  Tr,
  ThFixed,
  TdFixed,
  Th,
  Td,
} from "../../../../components";
import { TableNumber, DateConvert } from "../../../../utilities";
import { PurchaseOrderApi } from "../../../../api";
import { Row, Col } from "react-bootstrap";

const PurchaseOrder = ({ setNavbarTitle }) => {
  const history = useHistory();
  const location = useLocation();
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [dataPurchaseOrder, setDataPurchaseOrder] = useState([]);
  const [paginationConfig, setPaginationConfig] = useState({
    page: "1",
    dataLength: "10",
    totalPage: "1",
    dataCount: "0",
    key: "",
  });
  const [searchConfig, setSearchConfig] = useState({
    status: false,
    key: "",
  });
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: "primary",
    text: "",
  });

  const getInitialData = () => {
    setIsPageLoading(true);

    PurchaseOrderApi.get({
      q: searchConfig.key,
      page: paginationConfig.page,
      per_page: paginationConfig.dataLength,
    })
      .then(({ data }) => {
        setDataPurchaseOrder(data.data);
        setPaginationConfig({
          ...paginationConfig,
          dataCount: data.data_count,
          totalPage: data.total_page,
        });
      })
      .catch(() =>
        setAlertConfig({
          show: true,
          variant: "danger",
          text: "Data gagal dimuat!",
        })
      )
      .finally(() => {
        setIsPageLoading(false);
      });
  };

  const checkAlert = () => {
    const locationState = location.state;

    if (locationState) {
      if (locationState.alert) {
        setAlertConfig({
          show: locationState.alert.show,
          text: locationState.alert.text,
          variant: locationState.alert.variant,
        });
      }
    }
  };

  useEffect(() => {
    setNavbarTitle("Purchase Order");
    getInitialData();
    checkAlert();

    return () => {
      setIsPageLoading(false);
    };
  }, [setNavbarTitle, searchConfig.key, paginationConfig.page, paginationConfig.dataLength]);

  const PageContent = () => {
    const generateStatusAPP = (value) => {
      if (value.toUpperCase() === "APP") {
        return "APPROVED";
      } else if (value.toUpperCase() === "REV") {
        return "REVISI";
      } else if (value.toUpperCase() === "REJ") {
        return "REJECT";
      } else if (value.toUpperCase() === "VER") {
        return "VERIFIED";
      }

      return "PENDING";
    };
    const generateStatusPR = (value) => {
      if (value.toUpperCase() === "OPEN") {
        return <b className="text-success">OPEN</b>;
      }

      return <b className="text-danger">CLOSE</b>;
    };

    const DataTable = () => (
      <Table>
        <THead>
          <Tr>
            <ThFixed>No</ThFixed>
            <ThFixed>Aksi</ThFixed>
            <ThFixed>Tgl. Purchase Order</ThFixed>
            <ThFixed>No. Purchase Order</ThFixed>
            <Th>Nama Vendor</Th>
            <ThFixed>Tgl. Pengiriman</ThFixed>
            <ThFixed>Status Approval</ThFixed>
            {/* <Th>Status Purchase Request</Th> */}
          </Tr>
        </THead>
        <TBody>
          {dataPurchaseOrder.map((val, index) => (
            <Tr key={index}>
              <TdFixed>
                {TableNumber(paginationConfig.page, paginationConfig.dataLength, index)}
              </TdFixed>
              <TdFixed className="d-flex justify-content-start align-items-center">
                <ReadButton
                  onClick={() =>
                    history.push("/transaksi/purchase-order/detail/" + val.id_purchase_order)
                  }
                />
                {(val.status_approval === "PEN" || val.status_approval === "REV") && (
                  <UpdateButton
                    onClick={() =>
                      history.push("/transaksi/purchase-order/ubah/" + val.id_purchase_order)
                    }
                  />
                )}
              </TdFixed>
              <Td>
                {val.tgl_purchase_order
                  ? DateConvert(new Date(val.tgl_purchase_order)).defaultDMY
                  : "-"}
              </Td>
              <Td>{val.no_purchase_order}</Td>
              <Td>
                <div style={{ width: "200px" }}>{val.nama_vendor}</div>
              </Td>
              <Td>
                {val.tgl_pengiriman ? DateConvert(new Date(val.tgl_pengiriman)).defaultDMY : "-"}
              </Td>
              <TdFixed>
                {val.status_approval ? generateStatusAPP(val.status_approval) : "-"}
              </TdFixed>
              {/* <Td>
                {val.status_purchase_request ? generateStatusPR(val.status_purchase_request) : "-"}
              </Td> */}
            </Tr>
          ))}
        </TBody>
      </Table>
    );

    return (
      <>
        <DataTable />
        <Pagination
          dataLength={paginationConfig.dataLength}
          dataNumber={
            paginationConfig.page * paginationConfig.dataLength - paginationConfig.dataLength + 1
          }
          dataPage={
            paginationConfig.dataCount < paginationConfig.dataLength
              ? paginationConfig.dataCount
              : paginationConfig.page * paginationConfig.dataLength
          }
          dataCount={paginationConfig.dataCount}
          currentPage={paginationConfig.page}
          totalPage={paginationConfig.totalPage}
          onPaginationChange={({ selected }) =>
            setPaginationConfig({
              ...paginationConfig,
              page: selected + 1,
            })
          }
          onDataLengthChange={(e) =>
            setPaginationConfig({
              ...paginationConfig,
              page: 1,
              dataLength: e.target.value,
            })
          }
        />
      </>
    );
  };

  return (
    <CRUDLayout>
      {/* head section */}
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <Row>
            <Col md="8">
              <InputSearch
                onChange={(e) => {
                  const key = e.target.value;
                  setSearchConfig({
                    ...searchConfig,
                    key: e.target.value,
                  });
                  setAlertConfig({
                    show: key ? true : false,
                    variant: "primary",
                    text: "Hasil dari pencarian: " + key,
                  });
                }}
              />
            </Col>
          </Row>
        </CRUDLayout.HeadSearchSection>
        <CRUDLayout.HeadButtonSection>
          <CreateButton
            onClick={() => history.push("/transaksi/purchase-order/vendor-terseleksi")}
            // onClick={() => history.push("/transaksi/purchase-order/tambah")}
          />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      {/* alert */}
      <div className="mt-2">
        <Alert
          show={alertConfig.show}
          variant={alertConfig.variant}
          text={alertConfig.text}
          showCloseButton={true}
          onClose={() =>
            setAlertConfig({
              ...alertConfig,
              show: false,
            })
          }
        />
      </div>

      {/* content */}
      {isPageLoading ? (
        <DataStatus loading={true} text="Memuat data . . ." />
      ) : dataPurchaseOrder ? (
        dataPurchaseOrder.length > 0 ? (
          <PageContent />
        ) : (
          <DataStatus text="Tidak ada data" />
        )
      ) : (
        <DataStatus text="Data gagal dimuat" />
      )}
    </CRUDLayout>
  );
};

export default PurchaseOrder;
