// React
import React, { useState, useEffect } from 'react'

// Router
import { useHistory, useParams } from 'react-router-dom'

// Component
import {
	CRUDLayout, DeleteButton, ReadButton,
	UpdateButton, PrintButton, DataStatus,
	Pagination, THead, TBody, ThFixed, Alert,
	TdFixed, Tr, Th, Td, Input, TextArea,
	SelectSearch, ActionButton, RadioButton
} from '../../../components'
import { Button, Form, Row, Col } from 'react-bootstrap'

// Form
import { Formik, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'

// API
import Axios from 'axios'
import { 
	PrakualifikasiVendorApi, VendorApi,
	ItemBahanApi, ItemUpahApi, ItemSubconApi
} from '../../../api'

// Icon
import { IoArrowBackOutline } from 'react-icons/io5'

const UbahPrakualifikasiVendor = ({setNavbarTitle}) => {
	let history = useHistory()
	let { id } = useParams()

	// indikator pemanggilan data sedang dimuat di server
	const [isLoading, setIsLoading] = useState(false)

	// Alert
	const [showAlert, setShowAlert] = useState(false)
	const [alertConfig, setAlertConfig] = useState({
    variant: "primary",
    text: "",
    status: false,
  })

	// Data View
	const [dataPrakualifikasi, setDataPrakualifikasi] = useState({
		dokumen_utama: [],
		jumlah_item: []
	})
	const [data, setData] = useState({
		jenis_vendor: []
	})
	const [dataNo, setDataNo] = useState("")
	const [dataBahan, setDataBahan] = useState([])
	const [dataUpah, setDataUpah] = useState([])
	const [dokumenLain, setDokumenLain] = useState("")
	const [dataDokumenLain, setDataDokumenLain] = useState([])
	const dataDokumen = [
		"Copy NPWP",
		"Copy KTP",
		"Copy SIUP",
		"Copy Tanda Daftar Perusahaan",
		"Kemampuan Modal Usaha",
		"Referensi Bank",
		"Sertifikat Produk/Sertiikat Sistem Mutu",
		"Surat Penunjukan Keagenan",
	]

	const getData = () => {
		setIsLoading(true)

		Axios.all([
			PrakualifikasiVendorApi.single(id),
			PrakualifikasiVendorApi.getNoBaru(id),
		])
			.then(
				Axios.spread((res, no_baru) => {
					setDataPrakualifikasi(res.data.data)
					setDataDokumenLain([
						...res.data.data.dokumen_lainnya
					])
					setDataNo(no_baru.data.data)

					Axios.all([
						VendorApi.single(res.data.data.id_vendor),
						ItemBahanApi.get(res.data.data.id_vendor),
						ItemUpahApi.get(res.data.data.id_vendor),
					])
					.then(
						Axios.spread((res, bahan, upah) => {
							setData(res.data.data)
							setDataBahan(bahan.data.data)
							setDataUpah(upah.data.data)
						})
					)

					setIsLoading(false)
				})
			)
	}

	const getDokumenLain = () => {
		PrakualifikasiVendorApi.single(id)
			.then(res => setDataDokumenLain(res.data.data.dokumen_lainnya))
	}

	const tambahDokumenLain = (id_vendor_prakualifikasi, nama_dokumen) => {
		const value = {
			id_vendor_prakualifikasi: id_vendor_prakualifikasi,
			nama_dokumen: nama_dokumen
		}

		PrakualifikasiVendorApi.createDokumenLain(value)
      .then(() => getDokumenLain())
      .catch(err => alert(`Tambah Dokumen Lain gagal (${err})`))
	}

	const hapusDokumenLain = (id_vendor_prakualifikasi_dokumen_lainnya) => {
		const value = {
			id_vendor_prakualifikasi_dokumen_lainnya: id_vendor_prakualifikasi_dokumen_lainnya
		}

		PrakualifikasiVendorApi.deleteDokumenLain(value)
      .then(() => getDokumenLain())
      .catch(err => alert(`Hapus Dokumen Lain gagal (${err})`))
	}

	useEffect(() => {
		// set judul di Navbar
		setNavbarTitle('Ubah Data Prakualifikasi Vendor')

		// jalankan function request data ke server
		getData()

		// menangani pembersihan saat fetching data
		return () => {
			setIsLoading(false)
		}

	}, [setNavbarTitle])

	const ShowData = ({ title, text }) => (
		<div className="d-flex flex-row">
			<div className="text-secondary mb-2 mr-3" style={{ width: 170 }}>
				{title}
			</div>
			<span>:</span>
			<div
				className="text-dark"
				style={{
					paddingLeft: 5,
				}}
			>
				{text ? text : "-"}
			</div>
		</div>
	)

	const formInitialValues = {
    id_vendor_prakualifikasi: dataPrakualifikasi.id_vendor_prakualifikasi,
    tgl_vendor_prakualifikasi: dataPrakualifikasi.tgl_vendor_prakualifikasi,
    no_vendor_prakualifikasi: dataPrakualifikasi.no_vendor_prakualifikasi,
    status_hukum: dataPrakualifikasi.status_hukum,
    jumlah_karyawan: dataPrakualifikasi.jumlah_karyawan,
    lama_menyediakan_item: dataPrakualifikasi.lama_menyediakan_item,
    jumlah_item: dataPrakualifikasi.jumlah_item.map((val) => {
			return {
				id_vendor_prakualifikasi_jumlah_item: val.id_vendor_prakualifikasi_jumlah_item,
				jumlah_item: val.jumlah_item
			}
		}),
    dokumen_utama: dataPrakualifikasi.dokumen_utama.map((val, index) => {
			return {
				id_vendor_prakualifikasi_dokumen_utama: val.id_vendor_prakualifikasi_dokumen_utama,
				ada_atau_tidak: val.ada_atau_tidak
			}
		})
	}
  
	const formValidationSchema = Yup.object().shape({
		tgl_vendor_prakualifikasi: Yup.string().required("Tanggal Vendor Prakualifikasi wajib diisi"),
		status_hukum: Yup.string().required("Status Hukum wajib diisi"),
		jumlah_karyawan: Yup.number().required("Jumlah karyawan wajib diisi"),
		lama_menyediakan_item: Yup.string().required("Tanggal Vendor Prakualifikasi wajib diisi"),
	})
  
	const formSubmitHandler = values => {
		PrakualifikasiVendorApi.update(values)
      .then(() => {
        history.push("/master/prakualifikasi-vendor", {
					variant: "primary",
					text: "Ubah Prakualifikasi Vendor berhasil",
					status: true,
				});
      })
      .catch(err => setAlertConfig({
        variant: 'danger',
        text: `Ubah Prakualifikasi Vendor gagal! (${err})`
      }))
	}

	return (
		<>
			<div className="row mx-1 mb-2 justify-content-between">
				<h4>Data Prakualifikasi Vendor</h4>
				
					<Button 
						variant="outline-secondary" 
						size="md"
						onClick={() => history.push("/master/prakualifikasi-vendor")}
					>
						<IoArrowBackOutline size={20} />
						<span className="ml-2">Kembali Ke Tabel</span>
					</Button>
			</div>

			<div className="mb-3 p-3 bg-white rounded shadow-sm">
			<Alert
				show={showAlert}
				showCloseButton={true}
				variant={alertConfig.variant}
				text={alertConfig.text}
				onClose={() => setShowAlert(false)}
			/>
			{isLoading == true ? (
				<DataStatus loading={true} text="Memuat data..." />
			) : (
				<>
					<Formik
						initialValues={formInitialValues}
						validationSchema={formValidationSchema}
						onSubmit={formSubmitHandler}
					>
						{({values, errors, touched, isSubmitting, setFieldValue, handleChange, handleSubmit}) => (
							// console.log("values", values)
							<form
								className="py-2"
								onSubmit={handleSubmit}
							>
								<Row>
									<Col lg="6">
										<Input 
											label="Tanggal Prakualifikasi"
											type="date"
											name="tgl_vendor_prakualifikasi"
											value={values.tgl_vendor_prakualifikasi}
											error={errors.tgl_vendor_prakualifikasi && touched.tgl_vendor_prakualifikasi && true}
											errorText={errors.tgl_vendor_prakualifikasi}
											onChange={(e) => {
												setFieldValue('tgl_vendor_prakualifikasi', e.target.value)

												PrakualifikasiVendorApi.getNoBaru(e.target.value)
													.then(res => setFieldValue('no_vendor_prakualifikasi', res.data.data))
											}}
										/>
									</Col>
									<Col lg="6">
										<Input
											label="No Prakualifikasi"
											value={values.no_vendor_prakualifikasi}
											readOnly={true}
										/>
									</Col>
								</Row>

								<div className="mb-2">
									<small>Status Hukum Vendor</small><br></br>
									<label>
										<Field 
											type="radio" 
											name="status_hukum" 
											value={true}
											onChange={() => setFieldValue("status_hukum", true)}
											className="d-inline mx-2" />
											Berbadan Hukum
									</label>
									<label>
										<Field 
											type="radio" 
											name="status_hukum" 
											value={false}
											onChange={() => setFieldValue("status_hukum", false)}
											className="d-inline mx-2" />
											Tidak Berbadan Hukum
									</label>
									<ErrorMessage name="status_hukum" component="div" className="invalid-feedback" />
								</div>

								<Row>
									<Col lg="6">
										<div className="mb-2">
											<ShowData
												title="Kode Vendor"
												text={data.kode_vendor}
											/>

											<ShowData
												title="Nama Vendor"
												text={data.nama_vendor}
											/>

											<ShowData
												title="Jenis Vendor"
												text={data.jenis_vendor.map((val) => val.jenis_vendor).join(', ')}
											/>

											<ShowData
												title="Pimpinan Perusahaan"
												text={data.nama_pimpinan_perusahaan}
											/>
										</div>

										<div className="mb-2">
											<ShowData
												title="Contact Person"
												text={data.contact_person}
											/>

											<ShowData
												title="Telepon"
												text={data.telepon}
											/>

											<ShowData
												title="Email"
												text={data.email}
											/>

											<ShowData
												title="Website"
												text={data.website}
											/>

											<ShowData
												title="Media Sosial"
												text={data.media_sosial}
											/>
										</div>
									</Col>

									<Col lg="6">
										<div className="mb-2">
											<ShowData
												title="Alamat Vendor"
												text={data.alamat_vendor}
											/>

											<ShowData
												title="Desa"
												text={data.nama_desa}
											/>

											<ShowData
												title="Kecamatan"
												text={data.nama_kecamatan}
											/>

											<ShowData
												title="Kabupaten"
												text={data.nama_kabupaten}
											/>

											<ShowData
												title="Provinsi"
												text={data.nama_provinsi}
											/>

											<ShowData
												title="Kode Pos"
												text={data.kode_pos}
											/>
										</div>
									</Col>
								</Row>

								{data.jenis_vendor.map((value) => {
									if (value.jenis_vendor == "Mandor") {
										return (
											<>
												<small>List Item Upah Yang Disediakan Vendor Ini Sebagai Mandor</small>
												<CRUDLayout.Table>
													<thead>
														<tr>
															<th>No</th>
															<th>Kode Item Bahan</th>
															<th>Nama Item Bahan</th>
															<th>Satuan Pakai</th>
														</tr>
													</thead>

													<tbody>
														{dataUpah.length > 0 ? (
															dataUpah.map((val, index) => (
																<tr key={index}>
																	<td>{index + 1}</td>
																	<td>{val.kode_item}</td>
																	<td>{val.nama_item}</td>
																	<td>{val.nama_satuan}</td>
																</tr>
															))
														) : (
															<tr>
																<td colSpan="3" className="my-2 font-weight-bold text-center">Tidak Ada Data</td>
															</tr>
														)}
													</tbody>
												</CRUDLayout.Table>
											</>
										)
									} else if (value.jenis_vendor == "Supplier") {
										return (
											<>
												<small>List Item Bahan Yang Disediakan Vendor Ini Sebagai Supplier</small>
												<CRUDLayout.Table>
													<thead>
														<tr>
															<th>No</th>
															<th>Kode Item Bahan</th>
															<th>Nama Item Bahan</th>
															<th>Kelompok Bahan</th>
															<th>Jenis Bahan</th>
															<th>Satuan Pakai</th>
														</tr>
													</thead>
							
													<tbody>
														{dataBahan.length > 0 ? (
															dataBahan.map((val, index) => (
																<tr key={index}>
																	<td>{index + 1}</td>
																	<td>{val.kode_item}</td>
																	<td>{val.nama_item}</td>
																	<td>{val.nama_kelompok}</td>
																	<td>{val.nama_jenis}</td>
																	<td>{val.satuan_pakai}</td>
																</tr>
															))
														) : (
															<tr>
																<td colSpan="6" className="my-2 font-weight-bold text-center">Tidak Ada Data</td>
															</tr>
														)}
													</tbody>
												</CRUDLayout.Table>
											</>
										)
									}
								})}

								<Row>
									<Col lg="6">
										<Input 
											label="Jumlah Karyawan"
											name="jumlah_karyawan"
											value={values.jumlah_karyawan}
											error={errors.jumlah_karyawan && touched.jumlah_karyawan && true}
											errorText={errors.jumlah_karyawan}
											onChange={handleChange}
										/>
									</Col>
								</Row>

								{data.jenis_vendor.map((value) => {
									if (value.jenis_vendor == "Supplier") {
										return (
											<>
												<small>List Item Bahan Yang Disediakan Vendor Ini Sebagai Supplier</small>
												<CRUDLayout.Table>
													<thead>
														<tr>
															<th>No</th>
															<th>Kode Item Bahan</th>
															<th>Nama Item Bahan</th>
															<th>Satuan</th>
															<th>Jumlah</th>
														</tr>
													</thead>
							
													<tbody>
														{dataBahan.length > 0 ? (
															dataBahan.map((val, index) => (
																<tr key={index}>
																	<td>{index + 1}</td>
																	<td>{val.kode_item}</td>
																	<td>{val.nama_item}</td>
																	<td>{val.satuan_pakai}</td>
																	<td>
																		<Field
																			name={"jumlah_item["+ index +"][jumlah_item]"}
																			type="number"
																			required
																			// value={values.jumlah_item[index][jumlah_item]}
																			onChange={handleChange} />
																	</td>
																</tr>
															))
														) : (
															<tr>
																<td colSpan="6" className="my-2 font-weight-bold text-center">Tidak Ada Data</td>
															</tr>
														)}
													</tbody>
												</CRUDLayout.Table>
											</>
										)
									}
								})}

								<div className="mb-2">
									<small>Lama Vendor Menyediakan Item</small><br></br>
									<div>
										<label>
											<Field 
												type="radio" 
												name="lama_menyediakan_item" 
												value="1"
												onChange={handleChange} />
												Kurang Dari 1 (Satu) Tahun
										</label>
									</div>
									<div>
										<label>
											<Field 
												type="radio" 
												name="lama_menyediakan_item" 
												value="2"
												onChange={handleChange} />
												Antara 1 (Satu) Sampai 3 (Tiga) Tahun
										</label>
									</div>
									<div>
										<label>
											<Field 
												type="radio" 
												name="lama_menyediakan_item" 
												value="3"
												onChange={handleChange} />
												Diatas 3 (Tiga) Tahun
										</label>
									</div>
									<ErrorMessage name="lama_menyediakan_item" component="div" className="invalid-feedback" />
								</div>

								<small>Kelengkapan Dokumen</small>
								<CRUDLayout.Table>
									<THead>
										<Tr>
											<Th>No</Th>
											<Th>Uraian</Th>
											<Th>Ada</Th>
											<Th>Tidak Ada</Th>
										</Tr>
									</THead>

									<TBody>
										{dataDokumen.map((val, index) =>  {
											return(
												<Tr key={index}>
													<TdFixed>
														{index + 1}
													</TdFixed>
													<Td>{val}</Td>
													<TdFixed>
														<Field 
															type="radio" 
															name={"dokumen_utama["+ index +"][ada_atau_tidak]"}
															value={true}
															onChange={() => setFieldValue("dokumen_utama["+ index +"][ada_atau_tidak]", true)}
														/>
													</TdFixed>
													<TdFixed>
														<Field 
															type="radio" 
															name={"dokumen_utama["+ index +"][ada_atau_tidak]"}
															value={false}
															onChange={() => setFieldValue("dokumen_utama["+ index +"][ada_atau_tidak]", false)}
														/>
													</TdFixed>
												</Tr>
											)
										})}
									</TBody>
								</CRUDLayout.Table>

								<small>Dokumen Lainnya (Sebutkan)</small>
								<Row>
									<Col sm="9">
										<Input 
											label=""
											onChange={(val) => {
												setDokumenLain(val.target.value)
											}}
										/>
									</Col>
									<Col sm="3">
										<Button 
											color="primary"
											type="button"
											onClick={() => {
												if (dokumenLain != "") {
													tambahDokumenLain(dataPrakualifikasi.id_vendor_prakualifikasi, dokumenLain)
													setDokumenLain("")
												}
											}}>
											Tambah
										</Button>
									</Col>
								</Row>

								<CRUDLayout.Table>
									<THead>
										<Tr>
											<Th>No</Th>
											<Th>Uraian</Th>
											<Th></Th>
										</Tr>
									</THead>

									<TBody>
										{dataDokumenLain.length > 0 ? (
											dataDokumenLain.map((val, index) => {
												return (
													<Tr key={index}>
														<TdFixed>{index + 1}</TdFixed>
														<Td>{val.nama_dokumen}</Td>
														<TdFixed>
															<DeleteButton onClick={() => {
																hapusDokumenLain(val.id_vendor_prakualifikasi_dokumen_lainnya)
															}}/>
														</TdFixed>
													</Tr>
												)
											})
										) : (
											<tr>
												<td colSpan="3" className="my-2 font-weight-bold text-center">Tidak Ada Data</td>
											</tr>
										)}
									</TBody>
								</CRUDLayout.Table>

								<div className="d-flex justify-content-end pt-3">
									<ActionButton 
										type="submit"
										variant="primary"
										text="Simpan"
										loading={isSubmitting}
									/>
								</div>
							</form>
						)}
					</Formik>
				</>
			)}
		</div>
		</>
	)
}

export default UbahPrakualifikasiVendor