import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Modal, Row, Col, Card } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import Axios from "axios";
import {
  CRUDLayout,
  Alert,
  DataStatus,
  CreateButton,
  ReadButton,
  UpdateButton,
  DeleteButton,
  ActionButton,
  SelectSearch,
  InputSearch,
  Input,
  Pagination,
  Table,
  THead,
  TBody,
  Tr,
  ThFixed,
  TdFixed,
  Th,
  Td,
} from "../../../../components";
import { RupiahConvert } from "../../../../utilities";
import { TableNumber, DateConvert } from "../../../../utilities";
import { SeleksiVendorApi, VendorApi } from "../../../../api";

const SeleksiVendor = ({ setNavbarTitle }) => {
  const history = useHistory();
  const location = useLocation();
  const [isPageLoading, setIsPageLoading] = useState(true);

  const [dataSeleksiVendor, setDataSeleksiVendor] = useState([]);
  const [dataVendor, setDataVendor] = useState([]);
  const [dataUbahVendor, setDataUbahVendor] = useState({});
  const [idSeleksi, setIdSeleksi] = useState("");

  const [processedData, setProcessedData] = useState({});
  const [paginationConfig, setPaginationConfig] = useState({
    page: "1",
    dataLength: "10",
    totalPage: "1",
    dataCount: "0",
  });
  const [searchConfig, setSearchConfig] = useState({
    status: false,
    key: "",
  });
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: "primary",
    text: "",
  });
  const [modalConfig, setModalConfig] = useState({
    show: false,
    type: "delete",
  });

  const getInitialData = () => {
    setIsPageLoading(true);

    Axios.all([
      SeleksiVendorApi.get({
        q: searchConfig.key,
        page: paginationConfig.page,
        per_page: paginationConfig.dataLength,
      }),
      VendorApi.get(),
    ])
      .then(
        Axios.spread((seleksi, vendor) => {
          setDataSeleksiVendor(seleksi.data.data);
          setDataVendor(vendor.data.data);
          setPaginationConfig({
            ...paginationConfig,
            dataCount: seleksi.data.data_count,
            totalPage: seleksi.data.total_page,
          });
        })
      )
      .catch(() =>
        setAlertConfig({
          show: true,
          variant: "danger",
          text: "Data gagal dimuat!",
        })
      )
      .finally(() => {
        setIsPageLoading(false);
      });
  };

  const checkAlert = () => {
    const locationState = location.state;

    if (locationState) {
      if (locationState.alert) {
        setAlertConfig({
          show: locationState.alert.show,
          text: locationState.alert.text,
          variant: locationState.alert.variant,
        });
      }
    }
  };

  useEffect(() => {
    setNavbarTitle("Seleksi Vendor");
    getInitialData();
    checkAlert();

    return () => {
      setIsPageLoading(false);
    };
  }, [setNavbarTitle, searchConfig.key, paginationConfig.page, paginationConfig.dataLength]);

  const PageContent = () => {
    const generateStatus = (value) => {
      if (value.toUpperCase() === "APP") {
        return "APPROVED";
      } else if (value.toUpperCase() === "REV") {
        return "REVISI";
      } else if (value.toUpperCase() === "REJ") {
        return "REJECT";
      } else if (value.toUpperCase() === "VER") {
        return "VERIFIED";
      }

      return "PENDING";
    };
    const DataTable = () => (
      <Table>
        <THead>
          <Tr>
            <ThFixed>No</ThFixed>
            <ThFixed>Aksi</ThFixed>
            <Th>Tgl. Seleksi Vendor</Th>
            <Th>No. Seleksi Vendor</Th>
            <Th>No. Purchase Request</Th>
            <Th>Keperluan</Th>
            <Th>Tgl. Pemakaian</Th>
            <ThFixed>Status Approval</ThFixed>
            {/* <Th>Progress Pembelian</Th> */}
          </Tr>
        </THead>
        <TBody>
          {dataSeleksiVendor.map((val, index) => (
            <Tr key={index}>
              <TdFixed>
                {TableNumber(paginationConfig.page, paginationConfig.dataLength, index)}
              </TdFixed>
              <TdFixed className="d-flex">
                <ReadButton
                  onClick={
                    () => {
                      setModalConfig({
                        ...modalConfig,
                        type: "detail",
                        show: true,
                      });
                      setDataUbahVendor(val);
                      setIdSeleksi(val.id_seleksi_vendor);
                    }
                    // history.push("/transaksi/seleksi-vendor/detail/" + val.id_seleksi_vendor)
                  }
                />
                {(val.status_approval === "PEN" ||
                  val.status_approval === "REV" ||
                  val.status_approval === null) && (
                  <UpdateButton
                    onClick={
                      () => {
                        setModalConfig({
                          ...modalConfig,
                          type: "update",
                          show: true,
                        });
                        setDataUbahVendor(val);
                        setIdSeleksi(val.id_seleksi_vendor);
                      }

                      // history.push("/transaksi/seleksi-vendor/ubah/" + val.id_seleksi_vendor)
                    }
                  />
                )}
                {val.deletable && (
                  <DeleteButton
                    onClick={() => {
                      setProcessedData(val);
                      setModalConfig({
                        show: true,
                        type: "delete",
                      });
                    }}
                  />
                )}
              </TdFixed>
              <Td>
                {val.tgl_seleksi_vendor
                  ? DateConvert(new Date(val.tgl_seleksi_vendor)).defaultDMY
                  : "-"}
              </Td>
              <Td>{val.no_seleksi_vendor}</Td>
              <Td>{val.no_purchase_request}</Td>
              <Td>
                <span className="text-capitalize">{val.keperluan}</span>
              </Td>
              <Td>
                {val.tgl_pemakaian ? DateConvert(new Date(val.tgl_pemakaian)).defaultDMY : "-"}
              </Td>
              <TdFixed>{generateStatus(val.status_approval ?? "")}</TdFixed>
              {/* <Td>
                <ActionButton size="sm" className="col" text="Cek Progress" />
              </Td> */}
            </Tr>
          ))}
        </TBody>
      </Table>
    );

    return (
      <>
        <DataTable />
        <Pagination
          dataLength={paginationConfig.dataLength}
          dataNumber={
            paginationConfig.page * paginationConfig.dataLength - paginationConfig.dataLength + 1
          }
          dataPage={
            paginationConfig.dataCount < paginationConfig.dataLength
              ? paginationConfig.dataCount
              : paginationConfig.page * paginationConfig.dataLength
          }
          dataCount={paginationConfig.dataCount}
          currentPage={paginationConfig.page}
          totalPage={paginationConfig.totalPage}
          onPaginationChange={({ selected }) =>
            setPaginationConfig({
              ...paginationConfig,
              page: selected + 1,
            })
          }
          onDataLengthChange={(e) =>
            setPaginationConfig({
              ...paginationConfig,
              page: 1,
              dataLength: e.target.value,
            })
          }
        />
      </>
    );
  };

  const PageModal = () => {
    const [dataDetailModal, setDataDetailModal] = useState([]);
    const [modalAlertConfig, setModalAlertConfig] = useState({
      show: false,
      variant: "primary",
      text: "",
    });

    const getDetailModal = () => {
      Axios.all([SeleksiVendorApi.getSingle({ id_seleksi_vendor: idSeleksi })])
        .then(
          Axios.spread((res) => {
            // console.log(res.data.data);
            setDataDetailModal(res.data.data);
          })
        )
        .catch(() =>
          setModalAlertConfig({
            show: true,
            variant: "danger",
            text: "Data gagal dimuat!",
          })
        )
        .finally(() => {});
    };

    useEffect(() => {
      getDetailModal();
      return () => {};
    }, []);

    // console.log("testtis", dataDetailModal);
    const FormCard = () => {
      const InfoItem = ({ title1, value1, title2, value2 }) => (
        <div className="mb-2">
          <small>{title1}</small>
          <br />
          <b>{value1}</b>
          <div className="mt-1" />
          <small>{title2}</small>
          <br />
          <b>{value2}</b>
        </div>
      );
      return (
        <Card className="mt-4 mb-5">
          <Card.Header className="d-flex justify-content-between align-items-center">
            <b>Catatan Approval Seleksi Vendor</b>
          </Card.Header>
          <Card.Body>
            <Row>
              {dataDetailModal.stakeholder === undefined
                ? "Memuat Data"
                : dataDetailModal.stakeholder.map(
                    (val, index) =>
                      index !== 0 &&
                      val.status_approval !== "PEN" && (
                        <Col sm>
                          <InfoItem
                            title1={
                              val.status_approval === "VER" || val.status_approval === "REV"
                                ? `Pemeriksa ${
                                    val.approval_level !== "0" ? val.approval_level : ""
                                  }`
                                : val.status_approval === "APP"
                                ? "Pengesah"
                                : "Di Tolak Oleh"
                            }
                            value1={val.nama_karyawan ?? "-"}
                            title2="Catatan"
                            value2={val.catatan ?? "-"}
                          />
                          {console.log(val.status_approval)}
                        </Col>
                      )
                  )}
            </Row>
          </Card.Body>
        </Card>
      );
    };

    const DetailField = ({ title, text, line }) => (
      <div className="mb-2">
        <small className="text-secondary" style={{ opacity: 0.8, textTransform: "capitalize" }}>
          {title}
        </small>
        <div className="text-dark" style={{ fontWeight: 500 }}>
          <small>{text ? text : "-"}</small>
        </div>
        {line && <hr className="my-2" />}
      </div>
    );

    const InfoSection = () => {
      const InfoItem = ({ title, value, className }) => (
        <tr>
          <td>
            <small>{title}</small>
          </td>
          <td>
            <small className="pl-4 pr-2">:</small>
          </td>
          <td>
            <small className={className}>{value}</small>
          </td>
        </tr>
      );

      return (
        <>
          <Row>
            <Col>
              <table>
                <tbody>
                  <InfoItem
                    title="Tgl. Purchase Request"
                    value={
                      dataUbahVendor.tgl_purchase_request
                        ? DateConvert(new Date(dataUbahVendor.tgl_purchase_request)).detail
                        : "-"
                    }
                  />
                  <InfoItem
                    title="Tgl. Pemakaian"
                    value={
                      dataUbahVendor.tgl_pemakaian
                        ? DateConvert(new Date(dataUbahVendor.tgl_pemakaian)).detail
                        : "-"
                    }
                  />
                </tbody>
              </table>
            </Col>
            <Col>
              <table>
                <tbody>
                  <InfoItem
                    title="No. Purchase Request"
                    value={
                      dataUbahVendor.no_purchase_request ? dataUbahVendor.no_purchase_request : "-"
                    }
                  />
                  <InfoItem
                    title="Keperluan"
                    value={dataUbahVendor.keperluan ? dataUbahVendor.keperluan : "-"}
                    className="text-capitalize"
                  />
                </tbody>
              </table>
            </Col>
          </Row>
          <hr />
        </>
      );
    };

    const ModalTitle = () =>
      modalConfig.type.toLowerCase() === "detail" ? (
        <span className="text-primary">Detail Seleksi Vendor</span>
      ) : modalConfig.type.toLowerCase() === "update" ? (
        <span className="text-success">Ubah Seleksi Vendor</span>
      ) : modalConfig.type.toLowerCase() === "delete" ? (
        <span className="text-danger">Hapus Seleksi Vendor</span>
      ) : (
        <span className="text-primary">Tambah Seleksi Vendor</span>
      );

    const ModalForm = () => {
      const formInitialValues = {
        id_purchase_request: dataUbahVendor.id_purchase_request,
        id_vendor: dataUbahVendor.id_vendor,
        nama_vendor: dataUbahVendor.nama_vendor,
        tgl_seleksi_vendor: dataUbahVendor.tgl_seleksi_vendor,
        no_seleksi_vendor: dataUbahVendor.no_seleksi_vendor,

        kode_item: dataUbahVendor.kode_item,
        id_item_buaso: dataUbahVendor.id_item_buaso,
        nama_item: dataUbahVendor.nama_item,

        satuan_beli: dataUbahVendor.satuan_beli,
        qty_pr: dataUbahVendor.qty_pr,
        harga_perkiraan_sendiri: dataUbahVendor.harga_perkiraan_sendiri,
        qty_order:
          modalConfig.type.toLowerCase() === "update"
            ? dataUbahVendor.qty_order
            : dataUbahVendor.qty_ordered,
        harga_kesepakatan: dataUbahVendor.harga_kesepakatan
          ? dataUbahVendor.harga_kesepakatan
          : dataUbahVendor.harga_perkiraan_sendiri,

        dimensi: dataUbahVendor.dimensi ? dataUbahVendor.dimensi : "0",
        pj: dataUbahVendor.pj ? dataUbahVendor.pj : "",
        lb: dataUbahVendor.lb ? dataUbahVendor.lb : "",
        tb: dataUbahVendor.tb ? dataUbahVendor.tb : "",
      };

      const formValidationSchema = Yup.object().shape({
        id_vendor: Yup.string().required("Pilih vendor"),
        tgl_seleksi_vendor: Yup.string().required("Pilih tanggal seleksi vendor"),
        qty_order: Yup.string().required("Masukan qty"),
        harga_kesepakatan: Yup.string().required("Masukan harga kesepakatan"),
      });

      const formSubmitHandler = (values) => {
        const finalValues = {
          id_purchase_request: values.id_purchase_request,
          id_vendor: values.id_vendor,
          tgl_seleksi_vendor: values.tgl_seleksi_vendor,
          no_seleksi_vendor: values.no_seleksi_vendor,
          id_seleksi_vendor: idSeleksi,
          detail: [
            {
              id_item_buaso: values.id_item_buaso,
              qty_order: values.qty_order,
              harga_kesepakatan: values.harga_kesepakatan,
            },
          ],
        };

        SeleksiVendorApi.createOrUpdate(finalValues)
          .then((res) => {
            console.log("res", res);
            setAlertConfig({
              show: true,
              variant: "primary",
              text: `Berhasil ubah data`,
            });
          })
          .catch((err) =>
            setAlertConfig({
              show: true,
              variant: "danger",
              text: `Gagal ubah data ${err?.response?.data?.message ?? ""}`,
            })
          )
          .finally(() => {
            setModalConfig({ ...modalConfig, show: false });
            getInitialData();
          });
      };

      return (
        <Formik
          enableReinitialize
          initialValues={formInitialValues}
          validationSchema={formValidationSchema}
          onSubmit={formSubmitHandler}
        >
          {({
            values,
            errors,
            touched,
            isSubmitting,
            setFieldValue,
            handleChange,
            handleSubmit,
          }) => (
            <form onSubmit={handleSubmit}>
              <Modal.Body>
                {dataDetailModal.stakeholder === undefined ? (
                  <DataStatus loading={true} text="Memuat Data" />
                ) : (
                  <>
                    <Alert
                      showCloseButton
                      show={modalAlertConfig.show}
                      variant={modalAlertConfig.variant}
                      text={modalAlertConfig.text}
                      onClose={() =>
                        setModalAlertConfig({
                          ...modalAlertConfig,
                          show: false,
                        })
                      }
                    />
                    <InfoSection />
                    <Row>
                      <Col>
                        <Input
                          label="Tgl. Seleksi Vendor"
                          type="date"
                          name="tgl_seleksi_vendor"
                          value={values.tgl_seleksi_vendor}
                          error={errors.tgl_seleksi_vendor && touched.tgl_seleksi_vendor && true}
                          errorText={errors.tgl_seleksi_vendor}
                          onChange={(e) => {
                            setFieldValue("tgl_seleksi_vendor", e.target.value);
                          }}
                        />
                      </Col>
                      <Col>
                        <Input
                          type="text"
                          label="No. Seleksi Vendor"
                          placeholder="Pilih tanggal untuk menentukan nomor"
                          value={values.no_seleksi_vendor}
                          error={errors.no_seleksi_vendor && touched.no_seleksi_vendor && true}
                          errorText={errors.no_seleksi_vendor}
                          readOnly
                        />
                      </Col>
                    </Row>
                    <SelectSearch
                      label="Vendor"
                      placeholder="Pilih vendor"
                      defaultValue={
                        values.id_vendor
                          ? {
                              value: values.id_vendor,
                              label: values.nama_vendor,
                            }
                          : ""
                      }
                      option={dataVendor.map((val) => {
                        return {
                          value: val.id_vendor,
                          label: val.nama_vendor,
                        };
                      })}
                      onChange={(val) => {
                        setFieldValue("id_vendor", val.value);
                      }}
                      error={errors.id_vendor && touched.id_vendor && true}
                      errorText={errors.id_vendor && touched.id_vendor && errors.id_vendor}
                    />

                    <Input
                      label="Item Purchase Request"
                      type="text"
                      value={
                        // values.dimensi === "3"
                        //   ? `${values.nama_item} (${values.pj} X ${values.lb} X ${values.tb})`
                        //   : values.dimensi === "2"
                        //   ? `${values.nama_item} (${values.pj} X ${values.lb})`
                        //   : values.dimensi === "1"
                        //   ? `${values.nama_item} (${values.pj})`
                        //   : values.dimensi === "0"
                        //   ? values.nama_item
                        //   : values.nama_item
                        values.nama_item
                      }
                      readOnly
                    />
                    <Row>
                      <Col>
                        <Input
                          label="Qty. Purchase Request"
                          type="text"
                          value={values.qty_pr}
                          readOnly
                        />
                      </Col>
                      <Col>
                        <Input
                          label="Satuan Beli"
                          type="text"
                          value={values.satuan_beli}
                          readOnly
                        />
                      </Col>
                    </Row>
                    <Input
                      label="Harga Perkiraan Sendiri (HPS)"
                      type="text"
                      value={
                        RupiahConvert(parseInt(values.harga_perkiraan_sendiri).toString()).detail
                      }
                      readOnly
                    />
                    <Row>
                      <Col>
                        <Input
                          label="Qty. Order"
                          type="number"
                          name="qty_order"
                          placeholder="Masukan qty. order"
                          value={values.qty_order}
                          onChange={handleChange}
                          error={errors.qty_order && touched.qty_order && true}
                          errorText={errors.qty_order}
                        />
                      </Col>
                      <Col>
                        <Input
                          label="Harga Kesepakatan"
                          type="text"
                          placeholder="Masukan harga kesepakatan"
                          value={
                            RupiahConvert(parseInt(values.harga_kesepakatan).toString()).detail
                          }
                          onChange={(e) => {
                            const val = e.target.value;
                            const convert = RupiahConvert(val.toString()).default;
                            setFieldValue(
                              "harga_kesepakatan",
                              Number.isInteger(convert) ? convert : 0
                            );
                          }}
                          error={errors.harga_kesepakatan && touched.harga_kesepakatan && true}
                          errorText={errors.harga_kesepakatan}
                        />
                      </Col>
                    </Row>
                    <FormCard />
                  </>
                )}
              </Modal.Body>
              <Modal.Footer>
                {modalConfig.type.toLowerCase() === "update" ? (
                  <ActionButton
                    type="submit"
                    variant="success"
                    text="Ubah Data"
                    loading={isSubmitting}
                  />
                ) : (
                  <ActionButton
                    type="submit"
                    variant="primary"
                    text="ubah Data"
                    loading={isSubmitting}
                  />
                )}
              </Modal.Footer>
            </form>
          )}
        </Formik>
      );
    };

    const ModalDetail = () => {
      const [dataDetailModal, setDataDetailModal] = useState([]);
      const [modalAlertConfig, setModalAlertConfig] = useState({
        show: false,
        variant: "primary",
        text: "",
      });

      const getDetailModal = () => {
        Axios.all([SeleksiVendorApi.getSingle({ id_seleksi_vendor: idSeleksi })])
          .then(
            Axios.spread((res) => {
              setDataDetailModal(res.data.data);
            })
          )
          .catch(() =>
            setModalAlertConfig({
              show: true,
              variant: "danger",
              text: "Data gagal dimuat!",
            })
          )
          .finally(() => {});
      };

      useEffect(() => {
        getDetailModal();
        return () => {};
      }, []);

      const FormCard = () => {
        const InfoItem = ({ title1, value1, title2, value2 }) => (
          <div className="mb-2">
            <small>{title1}</small>
            <br />
            <b>{value1}</b>
            <div className="mt-1" />
            <small>{title2}</small>
            <br />
            <b>{value2}</b>
          </div>
        );
        return (
          <Card className="mt-4 mb-5">
            <Card.Header className="d-flex justify-content-between align-items-center">
              <b>Catatan Approval Seleksi Vendor</b>
            </Card.Header>
            <Card.Body>
              <Row>
                {dataDetailModal.stakeholder === undefined
                  ? "Memuat Data"
                  : dataDetailModal.stakeholder.map(
                      (val, index) =>
                        index !== 0 &&
                        val.status_approval !== "PEN" && (
                          <Col sm>
                            <InfoItem
                              title1={
                                val.status_approval === "VER" || val.status_approval === "REV"
                                  ? `Pemeriksa ${
                                      val.approval_level !== "0" ? val.approval_level : ""
                                    }`
                                  : val.status_approval === "APP"
                                  ? "Pengesah"
                                  : "Di Tolak Oleh"
                              }
                              value1={val.nama_karyawan ?? "-"}
                              title2="Catatan"
                              value2={val.catatan ?? "-"}
                            />
                            {console.log(val.status_approval)}
                          </Col>
                        )
                    )}
              </Row>
            </Card.Body>
          </Card>
        );
      };
      return (
        <>
          <Modal.Body>
            {dataDetailModal.stakeholder === undefined ? (
              <DataStatus loading={true} text="Memuat Data" />
            ) : (
              <>
                <InfoSection />
                <Row>
                  <Col>
                    <DetailField
                      title="Tgl. Seleksi Vendor"
                      text={dataUbahVendor.tgl_seleksi_vendor}
                      line
                    />
                  </Col>
                  <Col>
                    <DetailField
                      title="No. Seleksi Vendor"
                      text={dataUbahVendor.no_seleksi_vendor}
                      line
                    />
                  </Col>
                </Row>
                <DetailField title="Vendor" text={dataUbahVendor.nama_vendor} line />

                <DetailField title="Item Purchase Request" text={dataUbahVendor.nama_item} line />
                <Row>
                  <Col>
                    <DetailField title="Qty. Purchase Request" text={dataUbahVendor.qty_pr} line />
                  </Col>
                  <Col>
                    <DetailField title="Satuan Beli" text={dataUbahVendor.satuan_beli} line />
                  </Col>
                </Row>
                <DetailField
                  title="Harga Perkiraan Sendiri"
                  text={
                    dataUbahVendor.harga_perkiraan_sendiri
                      ? RupiahConvert(parseInt(dataUbahVendor.harga_perkiraan_sendiri).toString())
                          .detail
                      : "-"
                  }
                  line
                />
                <Row>
                  <Col>
                    <DetailField title="Qty. Order" text={dataUbahVendor.qty_order} line />
                  </Col>
                  <Col>
                    <DetailField
                      title="Harga Kesepakatan"
                      text={
                        dataUbahVendor.harga_kesepakatan
                          ? RupiahConvert(dataUbahVendor.harga_kesepakatan).detail
                          : "-"
                      }
                      line
                    />
                  </Col>
                </Row>
                <FormCard />
              </>
            )}
          </Modal.Body>
        </>
      );
    };

    const ModalDelete = () => {
      const [isDeleting, setIsDeleting] = useState(false);

      const deleteDataHandler = () => {
        setIsDeleting(true);

        SeleksiVendorApi.delete({ id_seleksi_vendor: processedData.id_seleksi_vendor })
          .then(() => {
            setAlertConfig({
              show: true,
              variant: "primary",
              text: "Data berhasil dihapus!",
            });
            setPaginationConfig({
              ...paginationConfig,
              page: 1,
            });
            getInitialData();
          })
          .catch(() =>
            setAlertConfig({
              show: true,
              variant: "danger",
              text: "Data gagal dihapus!",
            })
          )
          .finally(() =>
            setModalConfig({
              ...modalConfig,
              show: false,
            })
          );
      };

      useEffect(() => {
        return () => {
          setIsDeleting(false);
        };
      }, []);

      const ModalDeleteText = () => (
        <>
          <h5>
            <span>Hapus data dengan no. seleksi vendor: </span>
            <br />
            <b>{processedData.no_seleksi_vendor}</b>
          </h5>
          <small className="text-danger">Data yang dihapus tidak dapat dikembalikan!</small>
        </>
      );

      const ModalDeleteButton = () => (
        <div className="d-flex justify-content-center mt-3">
          <ActionButton
            variant="outline-secondary"
            className="m-1"
            text="Batal"
            onClick={() =>
              setModalConfig({
                ...modalConfig,
                show: false,
              })
            }
          />
          <ActionButton
            variant="danger"
            className="m-1"
            text="Hapus Data"
            loading={isDeleting}
            onClick={deleteDataHandler}
          />
        </div>
      );

      return (
        <>
          <Modal.Body className="text-center">
            <ModalDeleteText />
            <ModalDeleteButton />
          </Modal.Body>
        </>
      );
    };

    return (
      <Modal
        size={modalConfig.type.toLowerCase() === "delete" ? "md" : "lg"}
        show={modalConfig.show}
        onHide={() =>
          setModalConfig({
            ...modalConfig,
            show: false,
          })
        }
      >
        <Modal.Header closeButton>
          <ModalTitle />
        </Modal.Header>
        {modalConfig.type.toLowerCase() === "delete" ? (
          <ModalDelete />
        ) : modalConfig.type.toLowerCase() === "update" ? (
          <ModalForm />
        ) : (
          <ModalDetail />
        )}
      </Modal>
    );
  };

  return (
    <CRUDLayout>
      {/* head section */}
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <Row>
            <Col md="8">
              <InputSearch
                onChange={(e) => {
                  const key = e.target.value;
                  setSearchConfig({
                    ...searchConfig,
                    key: e.target.value,
                  });
                  setAlertConfig({
                    show: key ? true : false,
                    variant: "primary",
                    text: "Hasil dari pencarian: " + key,
                  });
                }}
              />
            </Col>
          </Row>
        </CRUDLayout.HeadSearchSection>
        <CRUDLayout.HeadButtonSection>
          <CreateButton
            onClick={() => history.push("/transaksi/seleksi-vendor/purchase-request")}
          />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      {/* alert */}
      <div className="mt-2">
        <Alert
          show={alertConfig.show}
          variant={alertConfig.variant}
          text={alertConfig.text}
          showCloseButton={true}
          onClose={() =>
            setAlertConfig({
              ...alertConfig,
              show: false,
            })
          }
        />
      </div>

      {/* content */}
      {isPageLoading ? (
        <DataStatus loading={true} text="Memuat data . . ." />
      ) : dataSeleksiVendor ? (
        dataSeleksiVendor.length > 0 ? (
          <PageContent />
        ) : (
          <DataStatus text="Tidak ada data" />
        )
      ) : (
        <DataStatus text="Data gagal dimuat" />
      )}
      {modalConfig.show && <PageModal />}
    </CRUDLayout>
  );
};

export default SeleksiVendor;
