// React
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

// API
import { PermintaanUpahProduksiApi } from "api";

// Component
import {
  CRUDLayout, InputSearch, Alert, Pagination, THead,
  TBody, Tr, ThFixed, TdFixed, Th, Td, DataStatus,
  ActionButton
} from "components";
import { DateConvert, RupiahConvert, TableNumber } from "utilities";
import { Row, Col, Modal } from "react-bootstrap";

const PermintaanUpahProduksi = ({ setNavbarTitle }) => {
  const title = "Permintaan Job Mix";

  const history = useHistory();

  // indikator pemanggilan data sedang dimuat di server (loading)
  const [isLoading, setIsLoading] = useState(false);

  // menampung value dari search form
  const [searchKey, setSearchKey] = useState("");

  // data
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [dataLength, setDataLength] = useState(10);
  const [totalData, setTotalData] = useState("");
  // menampilkan alert
  const [showAlert, setShowAlert] = useState(false);
  // configurasi alert
  const [alertConfig, setAlertConfig] = useState({
    variant: "primary",
    text: "",
  });
  const [alertConfigSearch, setAlertConfigSearch] = useState({
    data: false,
    variant: "primary",
    text: "",
  });
  // Modal
  const [dataModal, setDataModal] = useState({
    show: false,
    data: {
      id_jobmix_permintaan: "",
      tgl_jobmix_permintaan: "",
      no_jobmix_permintaan: "",
      id_jobmix: "",
      no_jobmix: "",
      id_buaso: "",
      nama_buaso: "",
      id_item_permintaan: "",
      kode_item: "",
      nama_item: "",
      qty_permintaan: "",
      qty_transfer: "",
      id_satuan: "",
      kode_satuan: "",
      nama_satuan: "",
      id_pabrik: "",
      kode_pabrik: "",
      nama_pabrik: "",
    }
  })

  // request data dari server
  const getData = () => {
    setIsLoading(true);

    // request data ke server
    PermintaanUpahProduksiApi.permintaan_page({
      page: page,
      per_page: dataLength,
      q: searchKey,
    })
      .then((res) => {
        setData(res.data.data);
        setTotalPage(res.data.total_page);
        setTotalData(res.data.data_count);
      })
      .catch(() => {
        setAlertConfig({
          variant: "danger",
          text: "Data gagal dimuat",
        });
        setShowAlert(true);
      })
      .finally(() => {
        if (searchKey !== "") {
          setAlertConfigSearch({
            data: true,
            variant: "primary",
            text: `Hasil Pencarian : ${searchKey}`,
          });
        } else {
          setAlertConfigSearch({
            data: false,
            variant: "primary",
            text: `Hasil Pencarian : ${searchKey}`,
          });
        }
        setIsLoading(false);
      });
  };

  useEffect(() => {
    // set Judul di Navbar
    setNavbarTitle(`List ${title}`);

    getData();

    return () => {
      setIsLoading(false);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setNavbarTitle, page, dataLength, searchKey]);

  const Table = () => {
    return (
      <>
        <CRUDLayout.Table>
          <THead>
            <Tr className="text-center">
              <ThFixed>No</ThFixed>
              <ThFixed>Tgl. Permintaan Job Mix</ThFixed>
              <ThFixed>No. Permintaan Job Mix</ThFixed>
              <ThFixed>No. Job Mix</ThFixed>
              <Th>Item Permintaan</Th>
              <Th>Qty. Permintaan Job Mix</Th>
              <Th>Pabrik</Th>
              <Th>Status</Th>
              <ThFixed>Aksi</ThFixed>
            </Tr>
          </THead>
          <TBody>
            {data.map((val, index) => {
              const qty_permintaan = val?.qty_permintaan ? parseInt(val.qty_permintaan) : 0
              const qty_transfer = val?.qty_transfer ? parseInt(val.qty_transfer) : 0
              return (
                <Tr key={index}>
                  <TdFixed>{TableNumber(page, dataLength, index)}</TdFixed>
                  <Td>{val.tgl_jobmix_permintaan ? DateConvert(new Date(val.tgl_jobmix_permintaan)).defaultDMY : '-'}</Td>
                  <TdFixed>{val.no_jobmix_permintaan ?? '-'}</TdFixed>
                  <Td>{val.no_jobmix ?? '-'}</Td>
                  <Td>{val.nama_item && val.kode_item ? `(${val.kode_item}) ${val.nama_item}` : '-'}</Td>
                  <Td>{val.qty_permintaan ? parseInt(val.qty_permintaan) : '-'}</Td>
                  <Td>{val.nama_pabrik ?? '-'}</Td>
                  <Td>
                    {qty_transfer < qty_permintaan ? "Qty. Belum Terpenuhi" : "Qty. Telah Terpenuhi"}
                  </Td>
                  <TdFixed>
                    {qty_transfer < qty_permintaan ? 
                      <ActionButton
                        text="Transfer"
                        size="sm"
                        onClick={() => history.push(`/transaksi/transfer-upah-job-mix/${val.id_jobmix_permintaan}`)} />
                    :
                      <ActionButton
                        text="Detail"
                        size="sm"
                        onClick={() => setDataModal({
                          show: true,
                          data: val
                        })} />
                    }
                  </TdFixed>
                </Tr>
              )
            })}
          </TBody>
        </CRUDLayout.Table>
        <Pagination
          dataLength={dataLength}
          dataNumber={page * dataLength - dataLength + 1}
          dataCount={totalData}
          currentPage={page}
          totalPage={totalPage}
          dataPage={page * dataLength}
          onPaginationChange={({ selected }) => setPage(selected + 1)}
          onDataLengthChange={(e) => {
            setPage(1);
            setDataLength(e.target.value);
          }}
        />
      </>
    );
  }

  const DataModal = () => {
    // STATES
    const [statusHistory, setStatusHistory] = useState({
      loading: false,
      status: true,
    })
    const [dataHistory, setDataHistory] = useState([])

    useEffect(() => {
      setStatusHistory({
        loading: true,
        status: false,
      })
      setDataHistory([])

      dataModal?.data?.id_jobmix_permintaan && PermintaanUpahProduksiApi.jobmix_transfer_upah({ id_jobmix_permintaan: dataModal.data.id_jobmix_permintaan })
        .then(res => {
          setDataHistory(res.data.data)

          setStatusHistory({
            loading: false,
            status: true,
          })
        })
        .catch(() => setStatusHistory({
          loading: false,
          status: false,
        }))
    }, [])

    const InfoItem = ({ title, value }) => (
      <tr>
        <td className="align-top">{title}</td>
        <td className="pl-4 pr-2 align-top">:</td>
        <td className="align-top">{value}</td>
      </tr>
    )

    return (
      <Modal
        size="lg" 
        show={dataModal.show}
        onHide={() => setDataModal({
          ...dataModal,
          show: false
        })}>
        <Modal.Header closeButton>
          <h5>Detail {title}</h5>
        </Modal.Header>

        <Modal.Body>
          <Row>
            <Col md>
              <table style={{ fontSize: "14px" }}>
                <tbody>
                  <InfoItem
                    title="Tgl. Permintaan Job Mix"
                    value={dataModal?.data?.tgl_jobmix_permintaan ? DateConvert(new Date(dataModal.data.tgl_jobmix_permintaan)).detail : "-"}
                  />
                  <InfoItem
                    title="No. Permintaan Job Mix"
                    value={dataModal?.data?.no_jobmix_permintaan ?? "-"}
                  />
                  <InfoItem
                    title="No. Job Mix"
                    value={dataModal?.data?.no_jobmix ?? "-"}
                  />
                </tbody>
              </table>
            </Col>

            <Col md>
              <table style={{ fontSize: "14px" }}>
                <tbody>
                  <InfoItem
                    title="Item Upah"
                    value={dataModal?.data?.nama_item && dataModal?.data?.kode_item ? `(${dataModal.data.kode_item}) ${dataModal?.data?.nama_item}` : "-"}
                  />
                  <InfoItem
                    title="Qty. Permintaan Job Mix"
                    value={dataModal?.data?.qty_permintaan ?? 0}
                  />
                  <InfoItem
                    title="Pabrik"
                    value={dataModal?.data?.nama_pabrik ?? "-"}
                  />
                </tbody>
              </table>
            </Col>
          </Row>

          <hr />
          <h6>Histori Transfer Upah Job Mix</h6>
          <CRUDLayout.Table>
            <THead>
              <Tr className="text-center">
                <ThFixed>No</ThFixed>
                <ThFixed>Tgl. Transfer Upah</ThFixed>
                <ThFixed>No. Transfer Upah</ThFixed>
                <Th>Item Upah</Th>
                <Th>Qty. Transfer Upah</Th>
                <Th>Vendor</Th>
                <Th>Harga Satuan</Th>
                <Th>Jumlah</Th>
              </Tr>
            </THead>
            <TBody>
              {statusHistory.loading ?
                <DataStatus loading text="Memuat Data" />
              :
                statusHistory.status ? 
                  dataHistory.length > 0 ? dataHistory.map((val, index) => {
                    return (
                      <Tr key={index}>
                        <TdFixed>{index + 1}</TdFixed>
                        <TdFixed>{val.tgl_jobmix_transfer_upah ? DateConvert(new Date(val.tgl_jobmix_transfer_upah)).defaultDMY : '-'}</TdFixed>
                        <TdFixed>{val.no_jobmix_transfer_upah ?? '-'}</TdFixed>
                        <Td>{val.nama_item && val.kode_item ? `(${val.kode_item}) ${val.nama_item}` : '-'}</Td>
                        <Td>{val.qty_transfer ? parseInt(val.qty_transfer) : '-'}</Td>
                        <Td>{val.nama_vendor ?? '-'}</Td>
                        <Td>{val.harga_satuan ? RupiahConvert(parseInt(val.harga_satuan).toString()).detail : '-'}</Td>
                        <Td>{val.harga_satuan && val.qty_transfer ? RupiahConvert((parseInt(val.harga_satuan) * parseInt(val.qty_transfer)).toString()).detail : '-'}</Td>
                      </Tr>
                    )
                  })
                  :
                    <Tr>
                      <Td className="text-center" colSpan="8">
                        <DataStatus text="Tidak Ada Data" />
                      </Td>
                    </Tr>
                :
                  <DataStatus text="Gagal Memuat Data" />
              }
            </TBody>
          </CRUDLayout.Table>
        </Modal.Body>
      </Modal>
    )
  }

  return (
    <CRUDLayout>
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <Row className="mb-3">
            <Col md="8">
              <InputSearch
                onChange={(e) => {
                  setTimeout(() => {
                    setSearchKey(e.target.value);
                    setPage(1);
                  }, 1000);
                }}
                onSubmit={(e) => e.preventDefault()}
              />
            </Col>
          </Row>
        </CRUDLayout.HeadSearchSection>
      </CRUDLayout.Head>
      {/* ALert */}
      <Alert
        show={showAlert}
        showCloseButton={true}
        variant={alertConfig.variant}
        text={alertConfig.text}
        onClose={() => setShowAlert(false)}
      />
      <Alert
        show={alertConfigSearch.data}
        showCloseButton={true}
        variant={alertConfigSearch.variant}
        text={alertConfigSearch.text}
        onClose={() => setShowAlert(false)}
      />

      {isLoading === true ? (
        <DataStatus loading={true} text="Memuat data..." />
      ) : data.length > 0 ? (
        <>
          <Table />
          <DataModal />
        </>
      ) : (
        <DataStatus text="Tidak ada data" />
      )}
    </CRUDLayout>
  );
};

export default PermintaanUpahProduksi;
