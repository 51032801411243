// React
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

// API
import { PermintaanUpahProduksiApi } from "api";

// Component
import {
  CRUDLayout, InputSearch, Alert, Pagination, THead,
  TBody, Tr, ThFixed, TdFixed, Th, Td, DataStatus,
  ReadButton, CreateButton
} from "components";
import { DateConvert, RupiahConvert, TableNumber } from "utilities";
import { Row, Col, Modal } from "react-bootstrap";

const PermintaanUpahProduksi = ({ setNavbarTitle }) => {
  const title = "Transfer Produksi Upah";

  const history = useHistory();

  // indikator pemanggilan data sedang dimuat di server (loading)
  const [isLoading, setIsLoading] = useState(false);

  // menampung value dari search form
  const [searchKey, setSearchKey] = useState("");

  // data
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [dataLength, setDataLength] = useState(10);
  const [totalData, setTotalData] = useState("");
  // menampilkan alert
  const [showAlert, setShowAlert] = useState(false);
  // configurasi alert
  const [alertConfig, setAlertConfig] = useState({
    variant: "primary",
    text: "",
  });
  const [alertConfigSearch, setAlertConfigSearch] = useState({
    data: false,
    variant: "primary",
    text: "",
  });
  // Modal
  const [dataModal, setDataModal] = useState({
    show: false,
    data: {
      catatan: "",
      harga_satuan: "",
      id_item_buaso: "",
      id_jobmix_permintaan: "",
      id_jobmix_transfer_upah: "",
      id_satuan: "",
      id_vendor: "",
      kode_item: "",
      kode_satuan: "",
      kode_vendor: "",
      nama_item: "",
      nama_satuan: "",
      nama_vendor: "",
      no_jobmix_permintaan: "",
      no_jobmix_transfer_upah: "",
      qty_transfer: "",
      tgl_jobmix_permintaan: "",
      tgl_jobmix_transfer_upah: "",
    }
  })

  // request data dari server
  const getData = () => {
    setIsLoading(true);

    // request data ke server
    PermintaanUpahProduksiApi.page({
      page: page,
      per_page: dataLength,
      q: searchKey,
    })
      .then((res) => {
        setData(res.data.data);
        setTotalPage(res.data.total_page);
        setTotalData(res.data.data_count);
      })
      .catch(() => {
        setAlertConfig({
          variant: "danger",
          text: "Data gagal dimuat",
        });
        setShowAlert(true);
      })
      .finally(() => {
        if (searchKey !== "") {
          setAlertConfigSearch({
            data: true,
            variant: "primary",
            text: `Hasil Pencarian : ${searchKey}`,
          });
        } else {
          setAlertConfigSearch({
            data: false,
            variant: "primary",
            text: `Hasil Pencarian : ${searchKey}`,
          });
        }
        setIsLoading(false);
      });
  };

  useEffect(() => {
    // set Judul di Navbar
    setNavbarTitle(title);

    getData();

    return () => {
      setIsLoading(false);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setNavbarTitle, page, dataLength, searchKey]);

  const Table = () => {
    return (
      <>
        <CRUDLayout.Table>
          <THead>
            <Tr className="text-center">
              <ThFixed>No</ThFixed>
              <ThFixed>Aksi</ThFixed>
              <ThFixed>Tgl. Transfer Upah</ThFixed>
              <ThFixed>No. Transfer Upah</ThFixed>
              <ThFixed>No. Permintaan Job Mix</ThFixed>
              <Th>Item Upah</Th>
              <Th>Qty. Transfer Upah</Th>
              <Th>Vendor</Th>
              <Th>Harga Satuan</Th>
              <Th>Jumlah</Th>
            </Tr>
          </THead>
          <TBody>
            {data.map((val, index) => (
              <Tr key={index}>
                <TdFixed>{TableNumber(page, dataLength, index)}</TdFixed>
                <TdFixed>
                  <ReadButton
                    onClick={() => setDataModal({
                      show: true,
                      data: val
                    })} />
                </TdFixed>
                <Td>{DateConvert(new Date(val.tgl_jobmix_transfer_upah)).defaultDMY}</Td>
                <TdFixed>{val.no_jobmix_transfer_upah}</TdFixed>
                <Td>{val.no_jobmix_permintaan}</Td>
                <Td>{`(${val.kode_item}) ${val.nama_item}`}</Td>
                <Td>{parseInt(val.qty_transfer)}</Td>
                <Td>{val.nama_vendor}</Td>
                <Td className="text-nowrap text-right">{RupiahConvert(parseInt(val.harga_satuan).toString()).detail}</Td>
                <Td className="text-nowrap text-right">{RupiahConvert((parseInt(val.harga_satuan) * parseInt(val.qty_transfer)).toString()).detail}</Td>
              </Tr>
            ))}
          </TBody>
        </CRUDLayout.Table>
        <Pagination
          dataLength={dataLength}
          dataNumber={page * dataLength - dataLength + 1}
          dataCount={totalData}
          currentPage={page}
          totalPage={totalPage}
          dataPage={page * dataLength}
          onPaginationChange={({ selected }) => setPage(selected + 1)}
          onDataLengthChange={(e) => {
            setPage(1);
            setDataLength(e.target.value);
          }}
        />
      </>
    );
  }

  const DataModal = () => {
    const InfoItem = ({ title, value }) => (
      <tr>
        <td>{title}</td>
        <td className="pl-4 pr-2">:</td>
        <td>{value}</td>
      </tr>
    )

    return (
      <Modal 
        show={dataModal.show}
        onHide={() => setDataModal({
          ...dataModal,
          show: false
        })}>
        <Modal.Header closeButton>
          <h5>{`Detail ${title}`}</h5>
        </Modal.Header>

        <Modal.Body>
          <table style={{ fontSize: "14px" }}>
            <tbody>
              <InfoItem
                title="Tgl. Transfer Upah"
                value={dataModal?.data?.tgl_jobmix_transfer_upah ? DateConvert(new Date(dataModal.data.tgl_jobmix_transfer_upah)).detail : "-"}
              />
              <InfoItem
                title="No. Transfer Upah"
                value={dataModal?.data?.no_jobmix_transfer_upah ?? "-"}
              />
              <InfoItem
                title="No. Permintaan Job Mix"
                value={dataModal?.data?.no_jobmix_permintaan ?? "-"}
              />
              <InfoItem
                title="Item Bahan"
                value={dataModal?.data?.nama_item ?? "-"}
              />
              <InfoItem
                title="Qty. Transfer Upah"
                value={dataModal?.data?.qty_transfer ? parseInt(dataModal.data.qty_transfer) : "-"}
              />
              <InfoItem
                title="Vendor"
                value={dataModal?.data?.nama_vendor ?? "-"}
              />
              <InfoItem
                title="Harga Satuan"
                value={dataModal?.data?.harga_satuan ? RupiahConvert(parseInt(dataModal.data.harga_satuan).toString()).detail : "-"}
              />
              <InfoItem
                title="Jumlah"
                value={dataModal?.data?.harga_satuan && dataModal?.data?.qty_transfer ? RupiahConvert((parseInt(dataModal.data.harga_satuan) * parseInt(dataModal.data.qty_transfer)).toString()).detail : "-"}
              />
              <InfoItem
                title="Catatan"
                value={dataModal?.data?.catatan ?? "-"}
              />
            </tbody>
          </table>
        </Modal.Body>
      </Modal>
    )
  }

  return (
    <CRUDLayout>
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <Row>
            <Col md="8">
              <InputSearch
                onChange={(e) => {
                  setTimeout(() => {
                    setSearchKey(e.target.value);
                    setPage(1);
                  }, 1000);
                }}
                onSubmit={(e) => e.preventDefault()}
              />
            </Col>
          </Row>
        </CRUDLayout.HeadSearchSection>

        <CRUDLayout.HeadButtonSection>
          <CreateButton onClick={() => history.push("/transaksi/transfer-upah-job-mix/list-permintaan")} />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>
      {/* ALert */}
      <Alert
        show={showAlert}
        showCloseButton={true}
        variant={alertConfig.variant}
        text={alertConfig.text}
        onClose={() => setShowAlert(false)}
      />
      <Alert
        show={alertConfigSearch.data}
        showCloseButton={true}
        variant={alertConfigSearch.variant}
        text={alertConfigSearch.text}
        onClose={() => setShowAlert(false)}
      />

      {isLoading === true ? (
        <DataStatus loading={true} text="Memuat data..." />
      ) : data.length > 0 ? (
        <>
          <Table />
          <DataModal />
        </>
      ) : (
        <DataStatus text="Tidak ada data" />
      )}
    </CRUDLayout>
  );
};

export default PermintaanUpahProduksi;
