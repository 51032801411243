import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import Axios from "axios";
import { Formik } from "formik";
import { Modal } from "react-bootstrap";
import {
  CRUDLayout,
  Input,
  InputSearch,
  // ExportButton,
  CreateButton,
  ActionButton,
  DataStatus,
  CreateModal,
  UpdateModal,
  DeleteModal,
  UpdateButton,
  DeleteButton,
  Switch,
  Pagination,
  Select,
  Alert,
  THead,
  Tr,
  ThFixed,
  Th,
  TBody,
  TdFixed,
  Td,
} from "components";
import { KelompokUpahSubcontApi } from "api";
import { TableNumber } from "utilities";

const Kelompok = ({ setNavbarTitle }) => {
  // Title
  const title = "Kelompok Upah & Subcont";

  // STATE LOADING
  const [isLoading, setIsLoading] = useState(true);

  // STATE SEARCHING
  const [isSearching, setIsSearching] = useState(false);
  const [searchKey, setSearchKey] = useState("");

  // MODALS SHOW STATE
  const [isCreateForm, setIsCreateForm] = useState(false); // MODAL TAMBAH STATE
  const [isUpdateForm, setIsUpdateForm] = useState(false); // MODAL UPDATE STATE
  const [isDeleteData, setIsDeleteData] = useState(false); // MODAL HAPUS STATE

  // STATE DATA KELOMPOK
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [dataLength, setDataLength] = useState(10);
  const [totalData, setTotalData] = useState("");
  // STATE MENAMPUNG DATA YANG AKAN DI UPDATE
  const [updateData, setUpdateData] = useState([]);
  // STATE MENAMPUNG DATA YANG AKAN DI HAPUS
  const [deleteData, setDeleteData] = useState([]);
  // STATE MENAMPUNG DATA BUASO
  const [dataBuaso, setDataBuaso] = useState([]);

  // ALERT STATE
  const [showAlert, setShowAlert] = useState(false);
  // KONFIGURASI ALERT
  const [alertConfig, setAlertConfig] = useState({
    variant: "primary",
    text: "",
  });

  // FUNCTION GET DATA DARI SERVER
  const getData = () => {
    setIsLoading(true);
    setIsSearching(false);
    setShowAlert(false);

    Axios.all([
      KelompokUpahSubcontApi.getPage(page, dataLength, searchKey),
      KelompokUpahSubcontApi.getDataBuaso(),
    ])
      .then(
        Axios.spread((res, dataBuaso) => {
          setData(res.data.data);
          setDataBuaso(dataBuaso.data.data);
          setTotalPage(res.data.total_page);
          setTotalData(res.data.data_count);
        })
      )
      .catch((err) => alert(err))
      .finally(() => {
        if (searchKey != "") {
          setAlertConfig({
            variant: "primary",
            text: `Hasil Pencarian : ${searchKey}`,
          });
          setShowAlert(true);
        }
        setIsLoading(false);
      });
  };

  // FUNCTION SWITCH HIDDEN DATA
  const changeDataStatus = (status, id) => {
    setIsLoading(true);
    setShowAlert(false);

    const value = {
      id_kelompok: id,
    };

    const onLoadedSuccess = () => {
      setIsSearching(false);
      setAlertConfig({
        variant: "primary",
        text: "Ubah status data berhasil",
      });
      setShowAlert(true);
    };

    const onLoadedFailed = () => {
      setIsSearching(false);
      setAlertConfig({
        variant: "danger",
        text: "Ubah status data gagal",
      });
      setShowAlert(true);
    };

    status === true
      ? KelompokUpahSubcontApi.show(value)
          .then(() => onLoadedSuccess())
          .catch(() => onLoadedFailed())
          .finally(() => getData())
      : KelompokUpahSubcontApi.hide(value)
          .then(() => onLoadedSuccess())
          .catch(() => onLoadedFailed())
          .finally(() => getData());
  };

  // ON COMPONENT MOUNT
  useEffect(() => {
    setNavbarTitle(title); // SET JUDUL NAVBAR
    getData(); // GET DATA DARI SERVER

    // return () => {
    //   setIsLoading(false);
    //   setIsSearching(false);
    // };
  }, [setNavbarTitle, page, dataLength, searchKey]);

  // FORMIK VALIDATION SCHEMA DENGAN YUP VALIDATION
  const formValidationSchema = Yup.object().shape({
    id_buaso: Yup.string().required("Pilih BUASO"),
    nama_kelompok: Yup.string()
      .required("Masukan Nama Kelompok Upah & Subcont")
      .test("checkDuplicate", "Nama Kelompok Upah & Subcont Sudah Didaftarkan", (value) =>
        updateData.nama_kelompok !== value || isCreateForm === true
          ? KelompokUpahSubcontApi.checkDuplicate({ nama_kelompok: value, flag: ["UP", "SB"] })
              .then(() => true)
              .catch(() => false)
          : true
      ),
  });

  // MODAL TAMBAH COMPONENT
  const TambahModal = () => {
    // FORMIK INITIAL VALUES
    const formInitialValues = {
      id_buaso: "",
      nama_kelompok: "",
      keterangan_kelompok: "",
      flag: "",
    };

    // KIRIM DATA BARU KE SERVER
    const formSubmitHandler = (values) => {
      let finalValues = {};

      if (values.id_buaso === "4") {
        finalValues = {
          ...values,
          flag: "SB",
        };
      } else {
        finalValues = {
          ...values,
          flag: "UP",
        };
      }

      KelompokUpahSubcontApi.create(finalValues)
        .then((res) => {
          // SUCCESS ALERT
          setAlertConfig({
            variant: "primary",
            text: "Tambah data berhasil!",
          });
        })
        .catch((err) => {
          // const errMsg = Object.values(err.response?.data?.error) ?? []
          // ERROR ALERT
          setAlertConfig({
            variant: "danger",
            text: `Tambah data gagal! (${err.response.data.message})`,
            // text: `Tambah data gagal! <ul> ${errMsg.map(e => `<li>${e}</li>`).join("")} </ul>`,
          });
        })
        .finally(() => {
          // CLOSE MODAL
          setIsCreateForm(false);
          // TAMPILKAN ALERT
          setShowAlert(true);
          // FETCH DATA DARI SERVER
          getData();
        });
    };

    return (
      <CreateModal show={isCreateForm} onHide={() => setIsCreateForm(false)} title={title}>
        <Formik
          initialValues={formInitialValues}
          validationSchema={formValidationSchema}
          onSubmit={formSubmitHandler}
        >
          {({ values, errors, touched, isSubmitting, handleChange, handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <Modal.Body>
                <Select
                  label="BUASO"
                  name="id_buaso"
                  onChange={handleChange}
                  error={errors.id_buaso && touched.id_buaso && true}
                  errorText={errors.id_buaso}
                  defaultValue="" // untuk menyesuaikan nilai yang telah dipilih
                >
                  <option value="">Pilih BUASO</option>
                  {dataBuaso.map((e) => (
                    <option
                      key={e.id_buaso}
                      value={e.id_buaso}
                    >{`${e.kode_buaso} - ${e.nama_buaso}`}</option>
                  ))}
                </Select>

                <Input
                  label="Nama Kelompok Upah & Subcont"
                  type="text"
                  name="nama_kelompok"
                  placeholder="Nama Kelompok Upah & Subcont"
                  value={values.nama_kelompok}
                  onChange={handleChange}
                  error={errors.nama_kelompok && true}
                  errorText={errors.nama_kelompok}
                />

                <Input
                  label="Keterangan"
                  type="text"
                  name="keterangan_kelompok"
                  placeholder="Keterangan"
                  value={values.keterangan_kelompok}
                  onChange={handleChange}
                  error={errors.keterangan_kelompok && touched.keterangan_kelompok && true}
                  errorText={errors.keterangan_kelompok}
                />
              </Modal.Body>
              <Modal.Footer>
                <div className="d-flex justify-content-end">
                  <ActionButton
                    type="submit"
                    variant="primary"
                    text="Tambah"
                    className="mt-2 px-4"
                    loading={isSubmitting}
                  />
                </div>
              </Modal.Footer>
            </form>
          )}
        </Formik>
      </CreateModal>
    );
  };

  // MODAL UBAH COMPONENT
  const UbahModal = () => {
    const formInitialValues = {
      id_buaso: updateData.id_buaso,
      nama_kelompok: updateData.nama_kelompok,
      keterangan_kelompok: updateData.keterangan_kelompok,
    };

    // KIRIM UPDATE DATA KE SERVER
    const formSubmitHandler = (values) => {
      let finalValues = {};

      if (values.id_buaso === "4") {
        finalValues = {
          id_kelompok: updateData.id_kelompok,
          flag: "SB",
          ...values,
        };
      } else {
        finalValues = {
          id_kelompok: updateData.id_kelompok,
          flag: "UP",
          ...values,
        };
      }
      // SEND UPDATED DATA TO SERVER
      KelompokUpahSubcontApi.update(finalValues)
        .then((res) => {
          // SUCCESS ALERT
          setAlertConfig({
            variant: "primary",
            text: "Ubah data berhasil!",
          });
        })
        .catch((err) => {
          // const errMsg = Object.values(err.response?.data?.error) ?? []
          // ERROR ALERT
          setAlertConfig({
            variant: "danger",
            text: `Ubah data gagal! (${err.response.data.message})`,
            // text: `Ubah data gagal! <ul> ${errMsg.map(e => `<li>${e}</li>`).join("")} </ul>`,
          });
        })
        .finally(() => {
          // CLOSE UPDATE MODAL FORM
          setIsUpdateForm(false);
          // SHOW ALERT
          setShowAlert(true);
          // FETCH DATA DARI SERVER
          getData();
        });
    };

    return (
      <UpdateModal show={isUpdateForm} onHide={() => setIsUpdateForm(false)} title={title}>
        <Formik
          initialValues={formInitialValues}
          validationSchema={formValidationSchema}
          onSubmit={formSubmitHandler}
        >
          {({ values, errors, touched, isSubmitting, handleChange, handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <Modal.Body>
                <Select
                  label="BUASO"
                  name="id_buaso"
                  onChange={handleChange}
                  error={errors.id_buaso && touched.id_buaso && true}
                  errorText={errors.id_buaso}
                  defaultValue={updateData.id_buaso} // untuk menyesuaikan nilai yang telah dipilih
                >
                  <option value="">Pilih BUASO</option>
                  {dataBuaso.map((e) => (
                    <option
                      key={e.id_buaso}
                      value={e.id_buaso}
                    >{`${e.kode_buaso} - ${e.nama_buaso}`}</option>
                  ))}
                </Select>

                <Input
                  label="Nama Kelompok Upah & Subcont"
                  type="text"
                  name="nama_kelompok"
                  placeholder="Nama Kelompok Upah & Subcont"
                  value={values.nama_kelompok}
                  onChange={handleChange}
                  error={errors.nama_kelompok && true}
                  errorText={errors.nama_kelompok}
                />

                <Input
                  label="Keterangan"
                  type="text"
                  name="keterangan_kelompok"
                  placeholder="Keterangan"
                  value={values.keterangan_kelompok}
                  onChange={handleChange}
                  error={errors.keterangan_kelompok && touched.keterangan_kelompok && true}
                  errorText={errors.keterangan_kelompok}
                />
              </Modal.Body>
              <Modal.Footer>
                <div className="d-flex justify-content-end">
                  <ActionButton
                    type="submit"
                    variant="success"
                    text="Ubah"
                    className="mt-2 px-4"
                    loading={isSubmitting}
                  />
                </div>
              </Modal.Footer>
            </form>
          )}
        </Formik>
      </UpdateModal>
    );
  };

  // MODAL HAPUS COMPONENT
  const HapusModal = () => {
    // SET DATA ID YANG DIHAPUS
    const deleteValue = { id_kelompok: deleteData.id_kelompok };

    // MENANGANI DELETE BUTTON LOADING
    const [btnLoading, setBtnLoading] = useState(false);

    // DELETE DATA DARI SERVER
    const deleteDataHandler = () => {
      setBtnLoading(true);
      KelompokUpahSubcontApi.delete(deleteValue)
        .then((res) => {
          // KONFIGURASI ALERT
          setAlertConfig({
            variant: "primary",
            text: "Hapus data berhasil!",
          });
        })
        .catch((err) => {
          // KONFIGURASI ALERT
          setAlertConfig({
            variant: "danger",
            text: `Hapus data gagal! (${err.response.data.message})`,
          });
        })
        .finally(() => {
          // CLOSE MODAL
          setIsDeleteData(false);
          // TAMPIL ALERT
          setShowAlert(true);
          // FETCH DATA DARI SERVER
          getData();
        });
    };

    return (
      <DeleteModal
        show={isDeleteData}
        onHide={() => setIsDeleteData(false)}
        loading={btnLoading}
        onConfirm={deleteDataHandler}
        title={title}
      >
        <div>Nama Kelompok Upah & Subcont : {deleteData.nama_kelompok}</div>
      </DeleteModal>
    );
  };

  // TABLE COMPONENT
  const Table = () => {
    return (
      <>
        <CRUDLayout.Table>
          <THead>
            <Tr className="text-center">
              <ThFixed>No</ThFixed>
              <ThFixed>Aksi</ThFixed>
              <Th>BUASO</Th>
              <Th>Nama Kelompok Upah & Subcont</Th>
              <Th>Keterangan</Th>
            </Tr>
          </THead>
          <TBody>
            {data.map((val, index) => {
              return (
                <Tr key={val.id_kelompok}>
                  <TdFixed>{TableNumber(page, dataLength, index)}</TdFixed>
                  <TdFixed>
                    <div className="d-flex justify-content-center">
                      <UpdateButton
                        onClick={() => {
                          setUpdateData(val);
                          setIsUpdateForm(true);
                        }}
                      />
                      <DeleteButton
                        onClick={() => {
                          setDeleteData(val);
                          setIsDeleteData(true);
                        }}
                      />

                      <Switch
                        id={val.id_kelompok}
                        checked={val.is_hidden ? false : true}
                        onChange={() => changeDataStatus(val.is_hidden, val.id_kelompok)}
                      />
                    </div>
                  </TdFixed>
                  <Td>{val.nama_buaso}</Td>
                  <Td>{val.nama_kelompok}</Td>
                  <Td>{val.keterangan_kelompok}</Td>
                </Tr>
              );
            })}
          </TBody>
        </CRUDLayout.Table>
        {!isSearching && (
          <Pagination
            dataLength={dataLength}
            dataPage={
              totalData <= 10
                ? data.length
                : data.map((res, index) => {
                    if (index == data.length - 1) {
                      return TableNumber(page, dataLength, index);
                    }
                  })
            }
            dataNumber={data.map((res, index) => {
              if (index == 0) {
                return TableNumber(page, dataLength, index);
              }
            })}
            dataCount={totalData}
            onDataLengthChange={(e) => {
              setDataLength(e.target.value);
              setPage(1);
            }}
            currentPage={page}
            totalPage={totalPage}
            onPaginationChange={({ selected }) => setPage(selected + 1)}
          />
        )}
      </>
    );
  };

  return (
    <>
      <CRUDLayout>
        <CRUDLayout.Head>
          <CRUDLayout.HeadSearchSection>
            <div className="d-flex mb-3">
              <InputSearch
                onChange={(e) => {
                  setTimeout(() => {
                    setSearchKey(e.target.value);
                    setPage(1);
                  }, 1000);
                }}
                onSubmit={(e) => e.preventDefault()}
              />
            </div>
          </CRUDLayout.HeadSearchSection>

          {/* Button Section */}
          <CRUDLayout.HeadButtonSection>
            {/* Button Export */}
            {/* <ExportButton /> */}
            {/* Button Tambah */}
            <CreateButton onClick={() => setIsCreateForm(true)} />
          </CRUDLayout.HeadButtonSection>
        </CRUDLayout.Head>

        {/* Alert */}
        <Alert
          show={showAlert}
          showCloseButton={true}
          variant={alertConfig.variant}
          text={alertConfig.text}
          onClose={() => setShowAlert(false)}
        />

        {isLoading ? (
          <DataStatus loading={isLoading} text="Memuat Data" />
        ) : !Array.isArray(data) ? (
          <DataStatus text="Tidak dapat memuat data" />
        ) : data.length > 0 ? (
          <Table />
        ) : (
          <DataStatus text="Tidak ada data" />
        )}

        {/* MODAL */}
        <TambahModal />
        <UbahModal />
        <HapusModal />
      </CRUDLayout>
    </>
  );
};

export default Kelompok;
