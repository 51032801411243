import Services from "../../services";

class JenisVendorApi {
  get() {
    return Services.get("/jenis_vendor");
  }

  getKode() {
    return Services.get("/jenis_vendor/no_baru");
  }

  getPage(page, dataLength, key) {
    return Services.get(`/jenis_vendor/page/?page=${page}&per_page=${dataLength}&q=${key}`);
  }

  create(value) {
    return Services.post("/jenis_vendor", value);
  }

  single(id) {
    return Services.get("/jenis_vendor/single?id_jenis_vendor=" + id);
  }

  update(value) {
    return Services.put("/jenis_vendor", value);
  }

  delete(value) {
    return Services.post("/jenis_vendor/delete", value);
  }

  hide(value) {
    return Services.put("/jenis_vendor/hide", value);
  }

  show(value) {
    return Services.put("/jenis_vendor/show", value);
  }
  checkDuplicate(params) {
    return Services.get("/jenis_vendor/check_duplicate", { params });
  }
}

export default new JenisVendorApi();
