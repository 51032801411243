import React, { useState, useEffect } from "react";
import { Row, Col, Card, Modal } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import Axios from "axios";

import {
  Alert,
  BackButton,
  ActionButton,
  UpdateButton,
  DeleteButton,
  DataStatus,
  Input,
  SelectSearch,
  Table,
  THead,
  TBody,
  Tr,
  ThFixed,
  TdFixed,
  Th,
  Td,
  DatePicker,
} from "../../../../components";
import { DateConvert, RupiahConvert } from "../../../../utilities";
import { PurchaseOrderApi, SeleksiVendorApi, VendorApi } from "../../../../api";
import { IoAddOutline } from "react-icons/io5";

const TambahSeleksiVendor = ({ setNavbarTitle }) => {
  const history = useHistory();
  const { id } = useParams();
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [isFetchingFailed, setIsFetchingFailed] = useState(false);
  const [dataSeleksiVendor, setDataSeleksiVendor] = useState([]);
  const [dataVendor, setDataVendor] = useState([]);
  const [dataListItemSeleksiVendor, setDataListItemSeleksiVendor] = useState([]);
  const [processedData, setProcessedData] = useState({});

  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: "primary",
    text: "",
  });
  const [modalConfig, setModalConfig] = useState({
    show: false,
    type: "create",
  });

  const getInitialData = () => {
    Axios.all([SeleksiVendorApi.getSingle({ id_purchase_request: id }), VendorApi.get()])
      .then(
        Axios.spread((seleksi, vendor) => {
          setDataSeleksiVendor(seleksi.data.data);
          setDataVendor(vendor.data.data);
        })
      )
      .catch((err) => {
        console.log(err);
        setIsFetchingFailed(true);
        setAlertConfig({
          show: true,
          variant: "danger",
          text: "Data gagal dimuat!",
        });
      })
      .finally(() => setIsPageLoading(false));
  };

  useEffect(() => {
    setNavbarTitle("Tambah Seleksi Vendor");
    getInitialData();
    // getNoSeleksiVendor(today);

    return () => {
      setIsPageLoading(false);
    };
  }, []);

  const PageContent = () => {
    const ListItemPurchaseRequestSection = () => {
      const dataListPR = dataSeleksiVendor.pr_detail ? dataSeleksiVendor.pr_detail : [];
      const cantProcess = dataListItemSeleksiVendor.length > 0 ? true : false;

      const TableListItemPurchaseRequest = () => (
        <Table>
          <THead>
            <Tr>
              <ThFixed>No.</ThFixed>
              <ThFixed>Kode Item</ThFixed>
              <Th>Nama Item</Th>
              <Th>Satuan Beli</Th>
              <Th>Qty PR</Th>
              <Th>Qty Order</Th>
              <Th>Harga Perkiraan</Th>
              <Th>Status Seleksi</Th>
              <ThFixed>Aksi</ThFixed>
            </Tr>
          </THead>
          <TBody>
            {dataListPR.map((val, index) => (
              <Tr key={index}>
                <TdFixed>{index + 1}</TdFixed>
                <TdFixed>{val.kode_item}</TdFixed>
                <Td>
                  <div style={{ width: "200px" }}>
                    {/* {val.dimensi === "3"
                      ? `${val.nama_item} (${val.pj} X ${val.lb} X ${val.tb})`
                      : val.dimensi === "2"
                      ? `${val.nama_item} (${val.pj} X ${val.lb})`
                      : val.dimensi === "1"
                      ? `${val.nama_item} (${val.pj})`
                      : val.dimensi === "0"
                      ? val.nama_item
                      : val.nama_item} */}
                    {val.nama_item}
                  </div>
                </Td>
                <Td>{val.satuan_beli}</Td>
                <Td textRight>{val.qty_pr}</Td>
                <Td textRight>{val.qty_ordered}</Td>
                <Td textRight>{RupiahConvert(parseInt(val.harga_perkiraan_sendiri).toString()).detail}</Td>
                <Td>{val.qty_ordered === "0" 
                  ? <div className="text-danger text-center">Belum Terseleksi</div>
                  : <div className="text-success text-center">Sudah Terseleksi</div>}
                </Td>
                <Td>
                  <ActionButton
                    size="sm"
                    className="text-nowrap"
                    text={<IoAddOutline />}
                    disable={cantProcess}
                    onClick={() => {
                      setProcessedData(val);
                      setModalConfig({
                        show: true,
                        type: "create",
                      });
                    }}
                  />
                </Td>
              </Tr>
            ))}
          </TBody>
        </Table>
      );

      return (
        <>
          <div className="py-2 pl-1">
            <b>List Item Purchase Request</b>
          </div>
          {dataSeleksiVendor.pr_detail ? (
            dataSeleksiVendor.pr_detail.length > 0 ? (
              <TableListItemPurchaseRequest />
            ) : (
              <DataStatus text="Tidak ada data" />
            )
          ) : (
            <DataStatus text="Tidak ada data" />
          )}
        </>
      );
    };

    return <ListItemPurchaseRequestSection />;
  };

  const PageModal = () => {
    const [modalAlertConfig, setModalAlertConfig] = useState({
      show: false,
      variant: "primary",
      text: "",
    });

    const InfoSection = () => {
      const InfoItem = ({ title, value }) => (
        <tr>
          <td>
            <small>{title}</small>
          </td>
          <td>
            <small className="pl-4 pr-2">:</small>
          </td>
          <td>
            <small>{value}</small>
          </td>
        </tr>
      );

      return (
        <>
          <Row>
            <Col>
              <table>
                <tbody>
                  <InfoItem
                    title="Tgl. Purchase Request"
                    value={
                      dataSeleksiVendor.tgl_purchase_request
                        ? DateConvert(new Date(dataSeleksiVendor.tgl_purchase_request)).detail
                        : "-"
                    }
                  />
                  <InfoItem
                    title="Tgl. Pemakaian"
                    value={
                      dataSeleksiVendor.tgl_pemakaian
                        ? DateConvert(new Date(dataSeleksiVendor.tgl_pemakaian)).detail
                        : "-"
                    }
                  />
                </tbody>
              </table>
            </Col>
            <Col>
              <table>
                <tbody>
                  <InfoItem
                    title="No. Purchase Request"
                    value={
                      dataSeleksiVendor.no_purchase_request
                        ? dataSeleksiVendor.no_purchase_request
                        : "-"
                    }
                  />
                  <InfoItem
                    title="Keperluan"
                    value={dataSeleksiVendor.keperluan ? dataSeleksiVendor.keperluan : "-"}
                  />
                </tbody>
              </table>
            </Col>
          </Row>
          <hr />
        </>
      );
    };

    const ModalTitle = () =>
      modalConfig.type.toLowerCase() === "update" ? (
        <span className="text-success">Ubah Item Seleksi Vendor</span>
      ) : modalConfig.type.toLowerCase() === "delete" ? (
        <span className="text-danger">Hapus Item Seleksi Vendor</span>
      ) : (
        <span className="text-primary">Tambah Item Seleksi Vendor</span>
      );

    const ModalForm = () => {
      const [noBaru, setNoBaru] = useState("");
      const getNoSeleksiVendor = (date, setFieldValue) => {
        SeleksiVendorApi.getNomor({ tanggal: date })
          .then(({ data }) => {
            // setNoBaru(data.data);
            setFieldValue("no_seleksi_vendor", data.data)
          })
          .catch(() => {
            setModalAlertConfig({
              show: true,
              variant: "danger",
              text: "Nomor vendor gagal diperoleh!",
            });
          });
      };

      // useEffect(() => {
      //   getNoSeleksiVendor(new Date().toISOString().slice(0, 10));
      //   return () => {};
      // }, []);

      const formInitialValues = {
        id_purchase_request: id,
        tgl_seleksi_vendor: new Date(),
        no_seleksi_vendor: "",
        id_vendor: "",

        kode_item: processedData.kode_item,
        id_item_buaso: processedData.id_item_buaso,
        nama_item: processedData.nama_item,
        satuan_beli: processedData.satuan_beli,
        qty_pr: processedData.qty_pr,
        // qty_order: modalConfig.type.toLowerCase() === "update" ? processedData.qty_order : processedData.qty_ordered,
        qty_order: "0",
        harga_perkiraan_sendiri: processedData.harga_perkiraan_sendiri,
        harga_kesepakatan: processedData.harga_kesepakatan
          ? processedData.harga_kesepakatan
          : processedData.harga_perkiraan_sendiri,
        dimensi: processedData.dimensi ? processedData.dimensi : "0",
        pj: processedData.pj ? processedData.pj : "",
        lb: processedData.lb ? processedData.lb : "",
        tb: processedData.tb ? processedData.tb : "",
      };

      const formValidationSchema = Yup.object().shape({
        tgl_seleksi_vendor: Yup.string().required("Pilih tanggal seleksi vendor"),
        no_seleksi_vendor: Yup.string().required("Pilih tanggal untuk menentukan nomor"),
        id_vendor: Yup.string().required("Pilih vendor"),
        qty_order: Yup.string().required("Masukan qty"),
        harga_kesepakatan: Yup.string().required("Masukan harga kesepakatan"),
      });

      const formSubmitHandler = (values) => {
        const finalValues = {
          id_purchase_request: values.id_purchase_request,
          id_vendor: values.id_vendor,
          tgl_seleksi_vendor: values.tgl_seleksi_vendor,
          no_seleksi_vendor: values.no_seleksi_vendor,
          detail: [
            {
              id_item_buaso: values.id_item_buaso,
              qty_order: values.qty_order,
              harga_kesepakatan: parseInt(values.harga_kesepakatan),
            },
          ],
        };
        SeleksiVendorApi.createOrUpdate(finalValues)
          .then((res) => {
            console.log("res", res);
            setAlertConfig({
              show: true,
              variant: "primary",
              text: `Berhasil tambah data`,
            });
          })
          .catch((err) =>
            setAlertConfig({
              show: true,
              variant: "danger",
              text: `Gagal tambah data ${err?.response?.data?.message ?? ""}`,
            })
          )
          .finally(() => setModalConfig({ ...modalConfig, show: false }));
      };

      return (
        <Formik
          enableReinitialize
          initialValues={formInitialValues}
          validationSchema={formValidationSchema}
          onSubmit={formSubmitHandler}
        >
          {({
            values,
            errors,
            touched,
            isSubmitting,
            setFieldValue,
            handleChange,
            handleSubmit,
          }) => (
            <form onSubmit={handleSubmit}>
              <Modal.Body>
                <Alert
                  showCloseButton
                  show={modalAlertConfig.show}
                  variant={modalAlertConfig.variant}
                  text={modalAlertConfig.text}
                  onClose={() =>
                    setModalAlertConfig({
                      ...modalAlertConfig,
                      show: false,
                    })
                  }
                />
                { //noBaru ? 
                (
                  <>
                    <InfoSection />
                    <Row>
                      <Col>
                        <DatePicker
                          label="Tgl. Seleksi Vendor"
                          dateFormat="dd/MM/yyyy"
                          selected={
                            values.tgl_seleksi_vendor ? new Date(values.tgl_seleksi_vendor) : ""
                          }
                          onChange={(e) => {
                            const value = e.toISOString().slice(0, 10);
                            setFieldValue("tgl_seleksi_vendor", value);
                            getNoSeleksiVendor(value, setFieldValue);
                          }}
                          error={errors.tgl_seleksi_vendor && touched.tgl_seleksi_vendor && true}
                          errorText={errors.tgl_seleksi_vendor}
                        />
                      </Col>
                      <Col>
                        <Input
                          type="text"
                          label="No. Seleksi Vendor"
                          placeholder="Pilih tanggal untuk menentukan nomor"
                          value={values.no_seleksi_vendor}
                          error={errors.no_seleksi_vendor && touched.no_seleksi_vendor && true}
                          errorText={errors.no_seleksi_vendor}
                          readOnly
                        />
                      </Col>
                    </Row>
                    <SelectSearch
                      label="Vendor"
                      placeholder="Pilih vendor"
                      defaultValue={
                        values.id_vendor
                          ? {
                              value: values.id_vendor,
                              label: values.nama_vendor,
                            }
                          : ""
                      }
                      option={dataVendor.map((val) => {
                        return {
                          value: val.id_vendor,
                          label: val.nama_vendor,
                        };
                      })}
                      onChange={(val) => {
                        setFieldValue("id_vendor", val.value);
                      }}
                      error={errors.id_vendor && touched.id_vendor && true}
                      errorText={errors.id_vendor && touched.id_vendor && errors.id_vendor}
                    />

                    <Input
                      label="Item Purchase Request"
                      type="text"
                      value={
                        values.dimensi === "3"
                          ? `${values.nama_item} (${values.pj} X ${values.lb} X ${values.tb})`
                          : values.dimensi === "2"
                          ? `${values.nama_item} (${values.pj} X ${values.lb})`
                          : values.dimensi === "1"
                          ? `${values.nama_item} (${values.pj})`
                          : values.dimensi === "0"
                          ? values.nama_item
                          : values.nama_item
                      }
                      readOnly
                    />
                    <Row>
                      <Col>
                        <Input
                          label="Qty. Purchase Request"
                          type="text"
                          value={values.qty_pr}
                          readOnly
                        />
                      </Col>
                      <Col>
                        <Input
                          label="Satuan Beli"
                          type="text"
                          value={values.satuan_beli}
                          readOnly
                        />
                      </Col>
                    </Row>
                    <Input
                      label="Harga Perkiraan Sendiri (HPS)"
                      type="text"
                      value={
                        RupiahConvert(parseInt(values.harga_perkiraan_sendiri).toString()).detail
                      }
                      readOnly
                    />
                    <Row>
                      <Col>
                        <Input
                          label="Qty. Order"
                          type="number"
                          name="qty_order"
                          placeholder="Masukan qty. order"
                          value={values.qty_order}
                          onChange={handleChange}
                          error={errors.qty_order && touched.qty_order && true}
                          errorText={errors.qty_order}
                        />
                      </Col>
                      <Col>
                        <Input
                          label="Harga Kesepakatan"
                          type="text"
                          placeholder="Masukan harga kesepakatan"
                          value={
                            RupiahConvert(parseInt(values.harga_kesepakatan).toString()).detail
                          }
                          onChange={(e) => {
                            const val = e.target.value;
                            const convert = RupiahConvert(val.toString()).default;
                            setFieldValue(
                              "harga_kesepakatan",
                              Number.isInteger(convert) ? convert : 0
                            );
                          }}
                          error={errors.harga_kesepakatan && touched.harga_kesepakatan && true}
                          errorText={errors.harga_kesepakatan}
                        />
                      </Col>
                    </Row>
                  </>
                ) 
                // : (
                //   <DataStatus text="Memuat Data" loading={true} />
                // )
                }
              </Modal.Body>
              <Modal.Footer>
                {modalConfig.type.toLowerCase() === "update" ? (
                  <ActionButton
                    type="submit"
                    variant="success"
                    text="Ubah Data"
                    loading={isSubmitting}
                  />
                ) : (
                  <ActionButton
                    type="submit"
                    variant="primary"
                    text="Tambah Data"
                    loading={isSubmitting}
                  />
                )}
              </Modal.Footer>
            </form>
          )}
        </Formik>
      );
    };

    // const ModalDelete = () => {
    //   const [isDeleting, setIsDeleting] = useState(false);

    //   const deleteDataHandler = () => {
    //     setIsDeleting(true);

    //     const filteredData = dataListItemSeleksiVendor.filter(
    //       (value, index) => processedData.index !== index
    //     );

    //     setTimeout(() => {
    //       setDataListItemSeleksiVendor(filteredData);
    //       setIsDeleting(false);
    //       setModalConfig({
    //         ...modalConfig,
    //         show: false,
    //       });
    //       setAlertConfig({
    //         show: true,
    //         variant: "primary",
    //         text: "Data berhasil dihapus!",
    //       });
    //     }, 100);
    //   };

    //   useEffect(() => {
    //     return () => {
    //       setIsDeleting(false);
    //     };
    //   }, []);

    //   const ModalDeleteText = () => (
    //     <>
    //       <h5>
    //         <span>Hapus data dengan nama item: </span>
    //         <br />
    //         <b>{processedData.nama_item}</b>
    //       </h5>
    //       <small className="text-danger">Data yang dihapus tidak dapat dikembalikan!</small>
    //     </>
    //   );

    //   const ModalDeleteButton = () => (
    //     <div className="d-flex justify-content-center mt-3">
    //       <ActionButton
    //         variant="outline-secondary"
    //         className="m-1"
    //         text="Batal"
    //         onClick={() =>
    //           setModalConfig({
    //             ...modalConfig,
    //             show: false,
    //           })
    //         }
    //       />
    //       <ActionButton
    //         variant="danger"
    //         className="m-1"
    //         text="Hapus Data"
    //         loading={isDeleting}
    //         onClick={deleteDataHandler}
    //       />
    //     </div>
    //   );

    //   return (
    //     <>
    //       <Modal.Body className="text-center">
    //         <ModalDeleteText />
    //         <ModalDeleteButton />
    //       </Modal.Body>
    //     </>
    //   );
    // };

    return (
      <Modal
        size="lg"
        show={modalConfig.show}
        onHide={() =>
          setModalConfig({
            ...modalConfig,
            show: false,
          })
        }
      >
        <Modal.Header closeButton>
          <ModalTitle />
        </Modal.Header>
        <ModalForm />
        {/* {modalConfig.type.toLowerCase() === "delete" ? <ModalDelete /> : <ModalForm />} */}
      </Modal>
    );
  };

  return (
    <Card>
      <Card.Header className="d-flex justify-content-between align-items-center">
        <b>Tambah Data Seleksi Vendor</b>
        <BackButton onClick={() => history.goBack()} />
      </Card.Header>
      <Card.Body>
        <Alert
          showCloseButton
          show={alertConfig.show}
          variant={alertConfig.variant}
          text={alertConfig.text}
          onClose={() =>
            setAlertConfig({
              ...alertConfig,
              show: false,
            })
          }
        />
        {isPageLoading ? (
          <DataStatus loading={true} text="Memuat data . . ." />
        ) : isFetchingFailed ? (
          <DataStatus text="Data gagal dimuat, tidak dapat menambah data!" />
        ) : (
          <PageContent />
        )}
      </Card.Body>
      {modalConfig.show && <PageModal />}
    </Card>
  );
};

export default TambahSeleksiVendor;
