import Services from "services"

class TransferSubkonProduksiApi {
  get(params) {
    return Services.get("/transfer_produksi_subkon/page", { params })
  }

  getPreform(params) {
    return Services.get("/transfer_produksi_subkon/list_permintaan", { params })
  }

  getSingle(params) {
    return Services.get("/transfer_produksi_subkon/single", { params })
  }

  getHistory(params) {
    return Services.get("/transfer_produksi_subkon/histori", { params })
  }

  getNomor(params) {
    return Services.get("/transfer_produksi_subkon/no_baru", { params })
  }

  getDropdown(params) {
    return Services.get("/transfer_produksi_subkon/dropdown", { params })
  }

  save(data) {
    return Services.post("/transfer_produksi_subkon", data)
  }
}

export default new TransferSubkonProduksiApi()