import React, { PureComponent } from "react";
import { Row, Col } from "react-bootstrap";
import {
  DataStatus,
  Table,
  THead,
  TBody,
  Tr,
  ThFixed,
  TdFixed,
  Th,
  Td,
} from "../../../../../components";
import { DateConvert, RupiahConvert } from "../../../../../utilities";
import Logo from "../../../../../assets/image/logoPuspa.png";
import TTDBTS from "../../../../../assets/image/ttdBTS.png";
import TTD2 from "../../../../../assets/image/TTD2.png";
import TTD3 from "../../../../../assets/image/TTD3.png";

const PrintContent = ({ dataPurchaseOrder, dataPODetail }) => {
  const InfoItem = ({ title, value }) => (
    <tr>
      <td>
        <p>{title}</p>
      </td>
      <td>
        <p className="pl-4 pr-2">:</p>
      </td>
      <td>
        <p>{value}</p>
      </td>
    </tr>
  );

  const InfoItems = ({ title, value }) => (
    <tr>
      <td>{title}</td>
      <td className="pl-4 pr-2">:</td>
      <td>{value}</td>
    </tr>
  );

  let total = 0;
  let nilaiDiskon = 0;
  let setelahDiskon = 0;
  let nilaiPPN = 0;
  let setelahPPN = 0;

  dataPODetail.map((val, index) => {
    total = total + val.harga_kesepakatan * val.qty_order;
    nilaiDiskon = dataPurchaseOrder.diskon
      ? parseInt(dataPurchaseOrder.diskon) === 0
        ? 0
        : parseInt((dataPurchaseOrder.diskon / 100) * total)
      : 0;
    setelahDiskon = parseInt(total - nilaiDiskon);
    nilaiPPN = dataPurchaseOrder.ppn
      ? parseInt(dataPurchaseOrder.ppn) === 0
        ? 0
        : parseInt((dataPurchaseOrder.ppn / 100) * setelahDiskon)
      : 0;
    setelahPPN = parseInt(setelahDiskon + nilaiPPN);
  });

  console.log(dataPurchaseOrder);

  const CatatanSection = () => (
    <div>
      <div>Pesanan ini selambat-lambatnya telah sampai pada :</div>
      <Row>
        <Col>
          <table>
            <tbody>
              <InfoItems
                title="Tanggal"
                value={
                  dataPurchaseOrder.tgl_pengiriman
                    ? DateConvert(new Date(dataPurchaseOrder.tgl_pengiriman)).detail
                    : "-"
                }
              />
              <InfoItems title="Pambayaran" value={dataPurchaseOrder.pembayaran} />
              <InfoItems title="Dikirim ke" value={dataPurchaseOrder.alamat_tujuan_pengiriman} />
            </tbody>
          </table>
        </Col>
        <Col>
          <div className="d-flex justify-content-end">
            <table>
              <tbody>
                <InfoItems
                  title="Sub Total"
                  value={RupiahConvert(parseInt(total).toString()).detail}
                />
                <InfoItems
                  title={`Diskon ${dataPurchaseOrder.diskon} %`}
                  value={RupiahConvert(parseInt(nilaiDiskon).toString()).detail}
                />
                <InfoItems
                  title="Pengenaan Pajak"
                  value={RupiahConvert(parseInt(setelahDiskon).toString()).detail}
                />
                <InfoItems
                  title={`PPN ${dataPurchaseOrder.ppn} %`}
                  value={RupiahConvert(parseInt(nilaiPPN).toString()).detail}
                />
                <InfoItems
                  title={<b>Total</b>}
                  value={RupiahConvert(parseInt(setelahPPN).toString()).detail}
                />
              </tbody>
            </table>
          </div>
        </Col>
      </Row>
    </div>
  );

  const KopSuratSection = () => (
    <div className="d-flex mx-2">
      <div>
        <img src={Logo} width={110} />
      </div>
      <div style={{ marginLeft: 30 }}>
        <p>
          <b>PT. Puspa Beras Bali</b>
          <br />
          -<br />
          Phone : - <br />
        </p>
      </div>
    </div>
  );

  // console.log(dataPurchaseOrder);

  const InfoSection = () => {
    return (
      <>
        <KopSuratSection />
        <Row>
          <Col>
            <table>
              <tbody>
                <p>Deskripsi</p>
                <InfoItem
                  title="No. PO"
                  value={
                    dataPurchaseOrder.no_purchase_order ? dataPurchaseOrder.no_purchase_order : "-"
                  }
                />
                <InfoItem
                  title="Tanggal"
                  value={
                    dataPurchaseOrder.tgl_purchase_order
                      ? DateConvert(new Date(dataPurchaseOrder.tgl_purchase_order)).detail
                      : "-"
                  }
                />
                <InfoItem
                  title="Refrensi PR"
                  value={dataPurchaseOrder.contact_person ? dataPurchaseOrder.contact_person : "-"}
                />
              </tbody>
            </table>
          </Col>
          <Col>
            <table>
              <tbody>
                <InfoItem title="Kepada" />
                <span>{dataPurchaseOrder.nama_vendor ? dataPurchaseOrder.nama_vendor : "-"}</span>
                <br />
                <span>
                  {dataPurchaseOrder.alamat_vendor ? dataPurchaseOrder.alamat_vendor : "-"}
                </span>
              </tbody>
            </table>
          </Col>
        </Row>
      </>
    );
  };

  const TTDSection = () => {
    const TTDItems = ({ title, value, jabatan, src }) => (
      <Col className="text-center">
        <div>{title}</div>
        {/* <div style={{ height: 80 }}></div> */}
        <div>
          <img src={src} height="80" />
        </div>
        <div>
          <u>{value}</u>
        </div>
        <div>{jabatan}</div>
      </Col>
    );

    return (
      <Row className="mt-5 pt-3">
        <TTDItems
          title="Disahkan,"
          value="A.A Ngurah Temaja Putra"
          src={TTDBTS}
          jabatan="Direktur"
        />
        <TTDItems
          title="Disetujui,"
          value="I Ketut Nukayasa"
          src={TTD2}
          jabatan="Manager Adm & Keu"
        />
        <TTDItems title="Dibuat Oleh" value="Putu Subawa" src={TTD3} jabatan="Procurement" />
      </Row>
    );
  };

  const ListItemPurchaseOrderSection = () => {
    let total = 0;
    let nilaiDiskon = 0;
    let setelahDiskon = 0;
    let nilaiPPN = 0;
    let setelahPPN = 0;

    const TableFooterItems = ({ text, input, value }) => (
      <Tr>
        <Td colSpan={6} textRight>
          <b>{text}</b>
        </Td>
        <Td textRight colSpan={2}>
          <div className="d-flex align-items-center justify-content-between text-nowrap">
            {input ? (
              <div className="d-flex justify-content-center align-items-center">
                <div style={{ width: "70px" }}>{input}</div>
                <div className="pb-1 ml-2">
                  <b>%</b>
                </div>
              </div>
            ) : (
              <div></div>
            )}
            <div className="text-right">
              <b>{value}</b>
            </div>
          </div>
        </Td>
      </Tr>
    );

    return (
      <>
        <div className="py-2 pl-1">
          <span>Kami tempatkan order pembelian dengan sbb :</span>
        </div>
        {dataPODetail ? (
          dataPODetail.length > 0 ? (
            <>
              <Table>
                <THead>
                  <Tr>
                    <ThFixed>No.</ThFixed>
                    <ThFixed>Kode</ThFixed>
                    <Th>Nama Barang/Jasa</Th>
                    <Th>Qty</Th>
                    <Th>Satuan</Th>
                    <Th>Harga Satuan</Th>
                    <Th>Jumlah Harga</Th>
                  </Tr>
                </THead>
                <TBody>
                  {dataPODetail.map((val, index) => {
                    total = total + val.harga_kesepakatan * val.qty_order;
                    nilaiDiskon = dataPurchaseOrder.diskon
                      ? parseInt(dataPurchaseOrder.diskon) === 0
                        ? 0
                        : parseInt((dataPurchaseOrder.diskon / 100) * total)
                      : 0;
                    setelahDiskon = parseInt(total - nilaiDiskon);
                    nilaiPPN = dataPurchaseOrder.ppn
                      ? parseInt(dataPurchaseOrder.ppn) === 0
                        ? 0
                        : parseInt((dataPurchaseOrder.ppn / 100) * setelahDiskon)
                      : 0;
                    setelahPPN = parseInt(setelahDiskon + nilaiPPN);

                    return (
                      <Tr key={index}>
                        <TdFixed>{index + 1}</TdFixed>
                        <TdFixed>{val.kode_item}</TdFixed>
                        <Td>
                          <div style={{ width: "200px" }}>
                            {val.dimensi === "3"
                              ? `${val.nama_item} (${val.pj} X ${val.lb} X ${val.tb})`
                              : val.dimensi === "2"
                              ? `${val.nama_item} (${val.pj} X ${val.lb})`
                              : val.dimensi === "1"
                              ? `${val.nama_item} (${val.pj})`
                              : val.dimensi === "0"
                              ? val.nama_item
                              : ""}
                          </div>
                        </Td>
                        <Td textRight>{val.qty_order ? val.qty_order : "-"}</Td>
                        <Td>{val.nama_satuan ? val.nama_satuan : "-"}</Td>
                        <Td textRight>{RupiahConvert(val.harga_kesepakatan.toString()).detail}</Td>
                        <Td textRight>
                          {
                            RupiahConvert(
                              parseInt(val.qty_order * val.harga_kesepakatan).toString()
                            ).detail
                          }
                        </Td>
                      </Tr>
                    );
                  })}
                </TBody>
              </Table>
              <CatatanSection />
              {dataPurchaseOrder.stakeholder.length > 1 && <TTDSection />}
            </>
          ) : (
            <DataStatus text="Tidak ada data" />
          )
        ) : (
          <DataStatus text="Tidak ada data" />
        )}
      </>
    );
  };

  return (
    <div className="p-4">
      <InfoSection />
      <ListItemPurchaseOrderSection />
    </div>
  );
};

export default class PrintPurchaseOrder extends PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <PrintContent
        dataPurchaseOrder={this.props.dataPurchaseOrder}
        dataPODetail={this.props.dataPODetail}
      />
    );
  }
}
